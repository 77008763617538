const PropTypes = require('prop-types');
const React = require('react');
const connect = require('react-redux').connect;

import { closeSaveProjectMenu, closeDetailsProjectMenu, closeRemixProjectMenu } from '../../reducers/menus';

import ProjectModal from '../modal/project/modal.jsx';

require('./project-saving.scss');

const ProjectSaving = ({
    createProjectOnComplete,
    handleCloseProjectSaving,
    handleCompleteProjectSaving,
    isOpen,
    showCloseButton,
}) => (
    <div>
        <ProjectModal
            createProjectOnComplete={createProjectOnComplete}
            isOpen={isOpen}
            key="join-modal"
            showCloseButton={showCloseButton}
            onCompleteProjectSaving={handleCompleteProjectSaving}
            onRequestClose={handleCloseProjectSaving}
        />
    </div>
);

ProjectSaving.propTypes = {
    createProjectOnComplete: PropTypes.bool,
    handleCloseProjectSaving: PropTypes.func,
    handleCompleteProjectSaving: PropTypes.func,
    isOpen: PropTypes.bool,
    showCloseButton: PropTypes.bool,
};

const mapDispatchToProps = (dispatch, ownProps) => ({
    handleCloseProjectSaving: () => {
        if (ownProps.isDetails) {
            dispatch(closeDetailsProjectMenu());
        } else if (ownProps.isRemix) {
            dispatch(closeRemixProjectMenu());
        } else {
            dispatch(closeSaveProjectMenu());
        }
    },
    handleCompleteProjectSaving: () => {
        if (ownProps.isRemix) {
            dispatch(closeRemixProjectMenu());
        } else {
            dispatch(closeSaveProjectMenu());
        }
    }
});

export default connect(
    () => ({}),
    mapDispatchToProps
)(ProjectSaving);
