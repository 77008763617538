// Educabot - Familia BOT
// educabot-intro
import educabot11 from './steps/1.1.mp4';

import educabot12 from './steps/1.2.gif';
import educabot13 from './steps/1.3.gif';
import educabot14 from './steps/1.4.gif';
import educabot15 from './steps/1.5.gif';

import educabot21 from './steps/2.1.gif';
import educabot22 from './steps/2.2.gif';
import educabot23 from './steps/2.3.gif';
import educabot24 from './steps/2.4.gif';

import educabot31 from './steps/3.1.jpg';
import educabot32 from './steps/3.2.gif';
import educabot33 from './steps/3.3.gif';

import educabot41 from './steps/4.1.gif';
import educabot42 from './steps/4.2.gif';
import educabot43 from './steps/4.3.gif';
import educabot44 from './steps/4.4.gif';
import educabot45 from './steps/4.5.gif';
import educabot46 from './steps/4.6.gif';
import educabot47 from './steps/4.7.gif';
import educabot48 from './steps/4.8.gif';

import educabot51 from './steps/5.1.gif';
import educabot52 from './steps/5.2.gif';
import educabot53 from './steps/5.3.gif';
import educabot54 from './steps/5.4.gif';
import educabot55 from './steps/5.5.gif';
import educabot56 from './steps/5.6.gif';
import educabot57 from './steps/5.7.gif';
import educabot58 from './steps/5.8.gif';
import educabot59 from './steps/5.9.gif';
import educabot510 from './steps/5.10.gif';
import educabot511 from './steps/5.11.gif';

const enImages = {
    // educabot
    educabot11,
    
    educabot12,
    educabot13,
    educabot14,
    educabot15,

    educabot21,
    educabot22,
    educabot23,
    educabot24,
    
    educabot31,
    educabot32,
    educabot33,
    
    educabot41,
    educabot42,
    educabot43,
    educabot44,
    educabot45,
    educabot46,
    educabot47,
    educabot48,
    
    educabot51,
    educabot52,
    educabot53,
    educabot54,
    educabot55,
    educabot56,
    educabot57,
    educabot58,
    educabot59,
    educabot510,
    educabot511,

};

export {enImages};
