const bindAll = require('lodash.bindall');
const classNames = require('classnames');
const React = require('react');
const PropTypes = require('prop-types');
import {Formik} from 'formik';
const {injectIntl, intlShape} = require('react-intl');

const validate = require('../../lib/validate');
import FormikInput from '../../components/formik-forms/formik-input.jsx';
import JoinFlowStep from './join-flow-step.jsx';

require('./join-flow-steps.scss');

/*
 * Username step
 */
/* eslint-disable react/prefer-stateless-function, no-useless-constructor */
class NameStep extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'handleFocused',
            'handleSetNameRef',
            'handleValidSubmit',
            'validateNameIfPresent',
            'validateForm'
        ]);
        this.state = {
            focused: null
        };
        // simple object to memoize remote requests for usernames.
        // keeps us from submitting multiple requests for same data.
        this.usernameRemoteCache = Object.create(null);
    }
    componentDidMount () {
        // Send info to analytics when we aren't on the standalone page.
        // If we are on the standalone join page, the page load will take care of this.
        // if (window.location.pathname.indexOf('/join') === -1) {
        //     if (this.props.sendAnalytics) {
        //         this.props.sendAnalytics('join-username-modal');
        //     }
        // }

        // automatically start with focus on username field
        if (this.firstNameInput) this.firstNameInput.focus();
    }
    // track the currently focused input field, to determine whether each field should
    // display a tooltip. (We only display it if a field is focused and has never been touched.)
    handleFocused (fieldName) {
        this.setState({focused: fieldName});
    }
    handleSetNameRef (firstNameInputRef) {
        this.firstNameInput = firstNameInputRef;
    }
    validateNameIfPresent (name) {
        const localResult = validate.validateName(name);
        if (localResult.valid) return null;
        return this.props.intl.formatMessage({id: localResult.errMsgId});
    }
    // called asynchonously when form submit is initially requested,
    // along with all of the individual field validation functions
    validateForm (values) {
        // in addition to field-level username/password validations, we need to additionally
        // check that these values aren't blank.
        const errors = {};
        const firstNameResult = validate.validateName(values.firstName);
        if (!firstNameResult.valid) {
            errors.firstName = this.props.intl.formatMessage({id: firstNameResult.errMsgId});
        }

        const lastNameResult = validate.validateName(values.lastName);
        if (!lastNameResult.valid) {
            errors.lastName = this.props.intl.formatMessage({id: lastNameResult.errMsgId});
        }
        
        return errors;
    }
    // called after all validations pass with no errors
    handleValidSubmit (formData, formikBag) {
        formikBag.setSubmitting(false); // formik makes us do this ourselves
        this.props.onNextStep(formData);
    }
    render () {
        return (
            <Formik
                initialValues={{
                    firstName: '',
                    lastName: '',
                }}
                validate={this.validateForm}
                validateOnBlur={false}
                validateOnChange={false}
                onSubmit={this.handleValidSubmit}
            >
                {props => {
                    const {
                        errors,
                        handleSubmit,
                        isSubmitting,
                        setFieldError,
                        setFieldTouched,
                        setFieldValue,
                        touched,
                        validateField,
                        values
                    } = props;
                    return (
                        <JoinFlowStep
                            description={this.props.intl.formatMessage({
                                id: 'registration.nameStepDescriptionNonEducator'
                            })}
                            innerClassName="join-flow-inner-username-step"
                            title={this.props.intl.formatMessage({id: 'general.joinScratch'})}
                            waiting={isSubmitting}
                            onSubmit={handleSubmit}
                        >
                            <div>
                                <div className="join-flow-input-title">
                                    {this.props.intl.formatMessage({id: 'registration.createFirstName'})}
                                </div>
                                <FormikInput
                                    autoCapitalize="off"
                                    autoComplete="off"
                                    autoCorrect="off"
                                    className={classNames(
                                        'join-flow-input'
                                    )}
                                    error={errors.firstName}
                                    id="firstName"
                                    name="firstName"
                                    placeholder={this.props.intl.formatMessage({id: 'general.firstName'})}
                                    spellCheck={false}
                                    validate={this.validateNameIfPresent}
                                    validationClassName="validation-full-width-input"
                                    /* eslint-disable react/jsx-no-bind */
                                    onBlur={() => validateField('firstName')}
                                    onChange={e => {
                                        setFieldValue('firstName', e.target.value.substring(0, 30));
                                        setFieldTouched('firstName');
                                        setFieldError('firstName', null);
                                    }}
                                    onFocus={() => this.handleFocused('firstName')}
                                    /* eslint-enable react/jsx-no-bind */
                                    onSetRef={this.handleSetNameRef}
                                />
                                <div className="join-flow-password-section">
                                    <div className="join-flow-input-title">
                                        {this.props.intl.formatMessage({id: 'registration.createLastName'})}
                                    </div>
                                    <FormikInput
                                        autoCapitalize="off"
                                        autoComplete="off"
                                        autoCorrect="off"
                                        className={classNames(
                                            'join-flow-input'
                                        )}
                                        error={errors.lastName}
                                        id="lastName"
                                        name="lastName"
                                        placeholder={this.props.intl.formatMessage({id: 'general.lastName'})}
                                        spellCheck={false}
                                        validate={this.validateNameIfPresent}
                                        validationClassName="validation-full-width-input"
                                        /* eslint-disable react/jsx-no-bind */
                                        onBlur={() => validateField('lastName')}
                                        onChange={e => {
                                            setFieldValue('lastName', e.target.value.substring(0, 30));
                                            setFieldTouched('lastName');
                                            setFieldError('lastName', null);
                                        }}
                                        onFocus={() => this.handleFocused('lastName')}
                                    />
                                </div>
                            </div>
                        </JoinFlowStep>
                    );
                }}
            </Formik>
        );
    }
}

NameStep.propTypes = {
    intl: intlShape,
    onNextStep: PropTypes.func,
    // sendAnalytics: PropTypes.func.isRequired
};

const IntlNameStep = injectIntl(NameStep);

export default IntlNameStep;
