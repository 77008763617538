exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* #E5F0FF */ /* #E9F1FC */ /* #D9E3F2 */ /* 90% transparent version of motion-primary */ /* #FFFFFF */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 15% transparent version of black */ /* 15% transparent version of black */ /* #575E75 */ /* #4F36D6 */ /* #3373CC */ /* 35% transparent version of motion-primary */ /* 15% transparent version of motion-primary */ /* #FF661A */ /* #E64D00 */ /* #CF63CF */ /* #BD42BD */ /* #FFAB19 */ /* #FF8C1A */ /* #0FBD8C */ /* #0FBD8C */ /* #FF8C1A */ /* #FFB366 */ /* #FF8C1A */ /* #0FBD8C */ /* #0B8E69 */ /* 35% transparent version of extensions-primary */ /* opaque version of extensions-transparent, on white bg */ /* lighter than motion-primary */ /* make sure to keep these in sync with other constants,\ne.g. STAGE_DIMENSION_DEFAULTS in lib/screen-utils.js */ /* layout contants from `layout-constants.js` */ .controls_controls-container_2xinB {\n    display: -webkit-box;\n    display: -webkit-flex;\n    display: -ms-flexbox;\n    display: flex;\n} .controls_assignment-delivery-button_1dNln {\n    /* padding: .625rem 1rem; */\n    background: #42B3FF;\n    border-radius: 1.375rem;\n    color: hsla(0, 100%, 100%, 1);\n    /* height: $library-filter-bar-height; */\n} .controls_active_1Jx4T {\n    background: #27A8FF;\n}", ""]);

// exports
exports.locals = {
	"controls-container": "controls_controls-container_2xinB",
	"controlsContainer": "controls_controls-container_2xinB",
	"assignment-delivery-button": "controls_assignment-delivery-button_1dNln",
	"assignmentDeliveryButton": "controls_assignment-delivery-button_1dNln",
	"active": "controls_active_1Jx4T"
};