import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, intlShape, injectIntl } from 'react-intl';
import bindAll from 'lodash.bindall';
import { connect } from 'react-redux';
import * as routes from '@educabot/educablocks-cosmos';
import VM from 'scratch-vm';

import { setProjectUnchanged } from '../reducers/project-changed';
import {
    LoadingStates,
    getIsCreatingNew,
    getIsFetchingWithId,
    getIsLoading,
    getIsShowingProject,
    onFetchedProjectData,
    projectError,
    setProjectId,
    getIsLoadingAssignmentEducabot,
    getIsCloningAssignmentEducabot,
    getIsCreatingAssignmentEducabot,
    doneLoadingProject,
    doneCreatingProject,
    createProject,
    startFetchingEducabot,
    getIsFetchingEducabot,
    getIsLoadingEducabot,
    getIsShowingWithoutId,
    startUpdateLoadingAssignment,
} from '../reducers/project-state';
import { saveProjectDB, cloneAssignmentProject, getAssignmentSession } from './educabot-api';
import { openLoadingProject, closeLoadingProject } from '../reducers/modals';
import { setProjectTitle, projectTitleInitialState } from '../reducers/project-title';
import { showAlertWithTimeout } from '../reducers/alerts';
import {
    activateTab,
    BLOCKS_TAB_INDEX
} from '../reducers/editor-tab';

import log from './log';
import storage from './storage';

const messages = defineMessages({
    loadError: {
        defaultMessage: 'Can\'t load project\'s file',
        description: 'Message for loading project error',
        id: 'project.loadError'
    },
    notAnimationProject: {
        defaultMessage: 'This is not an Animations project',
        description: 'Message for not animation project error',
        id: 'project.notAnimationProject'
    }
});

/* Higher Order Component to provide behavior for loading projects by id. If
 * there's no id, the default project is loaded.
 * @param {React.Component} WrappedComponent component to receive projectData prop
 * @returns {React.Component} component with project loading behavior
 */
const ProjectFetcherHOC = function (WrappedComponent) {
    class ProjectFetcherComponent extends React.Component {
        constructor(props) {
            super(props);
            bindAll(this, [
                'fetchProject',
                'callBackCloneAssignmentProject',
                'callbackLoadAssignmentProject',
                'loadEducabotProjectSuccess',
            ]);
            storage.setProjectHost(props.projectHost);
            storage.setAssetHost(props.assetHost);
            storage.setTranslatorFunction(props.intl.formatMessage);
            // props.projectId might be unset, in which case we use our default;
            // or it may be set by an even higher HOC, and passed to us.
            // Either way, we now know what the initial projectId should be, so
            // set it in the redux store.
            if (
                props.projectId !== '' &&
                props.projectId !== null &&
                typeof props.projectId !== 'undefined'
            ) {
                this.props.setProjectId(props.projectId.toString());
            }
        }
        componentDidUpdate(prevProps) {
            if (prevProps.projectHost !== this.props.projectHost) {
                storage.setProjectHost(this.props.projectHost);
            }
            if (prevProps.assetHost !== this.props.assetHost) {
                storage.setAssetHost(this.props.assetHost);
            }
            if (this.props.isFetchingWithId && !prevProps.isFetchingWithId) {
                this.fetchProject(this.props.reduxProjectId, this.props.loadingState);
            }
            if (this.props.isShowingProject && !prevProps.isShowingProject) {
                this.props.onProjectUnchanged();
            }
            if (this.props.isShowingProject && (prevProps.isLoadingProject || prevProps.isCreatingNew)) {
                this.props.onActivateTab(BLOCKS_TAB_INDEX);
            }
            if ((this.props.isLoadingEducabot || this.props.isLoadingAssignmentEducabot)
                && this.props.projectDetails && this.props.vm) {
                this.loadEducabotProject(this.props.projectDetails);
            }
            if (this.props.isCloningAssignmentEducabot && this.props.assignmentId && this.props.projectDetails) {
                this.cloneAssignmentProject(this.props.projectDetails);
            }
            if (this.props.isShowingWithoutId && this.props.assignmentId && this.props.assignmentData) {
                this.createAssignmentProject(this.props.assignmentId, this.props.assignmentData);
            }
        }
        callbackLoadAssignmentProject() {
            if (this.props.assignmentId) {
            // if (this.props.assignmentId && this.props.assignmentData && this.props.projectDetails) {
                getAssignmentSession(this.props.assignmentId).then(result => {
                    const assignmentData = result.data;
                    const userId = (this.props.userData) ? this.props.userData.userId : 0;
                    // console.log('////////////////////////', userId, assignmentData);
                    let projectDetails = this.props.projectDetails || {};
                    for (let i = 0; i < assignmentData.AssignmentSessionsProjects.length; i += 1) {
                        if (assignmentData.AssignmentSessionsProjects[i].Project.userId === userId) {
                                projectDetails = assignmentData.AssignmentSessionsProjects[i].Project;
                        }
                    }
                    this.props.onStartUpdateLoadingAssignment(this.props.assignmentId, assignmentData, projectDetails.id, projectDetails, userId.toString());
                });
            } else if (this.props.projectDetails) {
                this.props.onDoneLoadingProject(this.props.projectDetails.id.toString(), this.props.projectDetails, this.props.projectDetails.userId.toString());
            }
            this.props.onCloseLoadingProject();
        }
        createAssignmentProject(assignmentId, assignmentData) {
            const userId = (this.props.userData) ? this.props.userData.userId : 0;
            for (let i = 0; i < assignmentData.AssignmentSessionsProjects.length; i += 1) {
                const project = assignmentData.AssignmentSessionsProjects[i].Project;
                if (project.userId === userId) {
                    this.props.onReceivedProjectTitle(assignmentData.title);
                    this.props.onDoneLoadingProject(project.id.toString(), project, userId);
                    this.callbackLoadAssignmentProject();
                    return;
                }
            }
            if (assignmentData && assignmentData.boardType && assignmentData.boardType === 'animations') {
                this.props.onOpenLoadingProject();
                this.props.onStartFetchingEducabot();
                // console.log('[FETCHER]:', assignmentId, assignmentData);
                this.props.vm.postIOData('video', { forceTransparentPreview: true });
                this.props.vm.renderer.requestSnapshot(canvasDataURI => {
                    this.props.vm.postIOData('video', { forceTransparentPreview: false });

                    this.props.saveProjectSb3().then(content => {
                        var formData = new FormData();
                        formData.append('title', assignmentData.title);
                        formData.append('description', assignmentData.description);
                        formData.append('boardType', assignmentData.boardType);
                        formData.append('assignmentSessionId', assignmentId);
                        formData.append('isLibrary', 0);
                        formData.append('isPublic', 0);
                        formData.append('canvasCss', '');
                        formData.append('imageUrl', assignmentData.imageUrl || '');
                        formData.append('jsonBody', JSON.stringify({
                            boardType: assignmentData.boardType,
                            projectZipFile: '',
                            projectImg: canvasDataURI,
                            assignmentProject: true,
                            assignmentSession: assignmentId,
                            assignmentProjectPassword: assignmentData.password || '',
                            assignmentProjectDeliveryDate: assignmentData.deliveryDate || '',
                            assignmentUserRole: 'OWNER',
                        }));
                        formData.append('animationZip', content, `${this.getProjectFilename(assignmentData.title, projectTitleInitialState)}`);
                        this.props.onCloneAssignmentProject(formData).then(response => {
                            console.log('PROJECT SAVED', response);
                            const userId = (this.props.userData) ? this.props.userData.userId : 0;
                            this.props.onReceivedProjectTitle(assignmentData.title);
                            this.props.onCreatedProject(response.data.projectId.toString(), assignmentData, userId.toString(), this.props.loadingState);
                            this.props.onShowAssignmentWellcomeAlert();
                            this.callbackLoadAssignmentProject();
                        }).catch(err => {
                            console.log('PROJECT SAVING ERROR', err);
                            this.props.onError(err);
                            this.props.onCloseLoadingProject();
                        });
                    });
                });
            } else {
                this.props.onError(this.props.intl.formatMessage(messages.notAnimationProject));
            }
        }
        callBackCloneAssignmentProject() {
            // console.log('AFTER CLONING... SAVE PROJECT');
            const projectData = this.props.projectDetails;
            this.props.vm.postIOData('video', { forceTransparentPreview: true });
            this.props.vm.renderer.requestSnapshot(canvasDataURI => {
                this.props.vm.postIOData('video', { forceTransparentPreview: false });

                this.props.saveProjectSb3().then(content => {
                    var formData = new FormData();
                    formData.append('title', projectData.title);
                    formData.append('description', projectData.description);
                    formData.append('boardType', 'animations');
                    formData.append('assignmentSessionId', this.props.assignmentId);
                    formData.append('isLibrary', 0);
                    formData.append('isPublic', 0);
                    formData.append('canvasCss', '');
                    formData.append('imageUrl', projectData.imageUrl || '');
                    const jsonBody = (projectData.jsonBody) ? projectData.jsonBody : {};
                    jsonBody.boardType = 'animations';
                    jsonBody.projectZipFile = '';
                    jsonBody.projectImg = canvasDataURI;
                    jsonBody.assignmentUserRole = 'STUDENT';
                    formData.append('jsonBody', JSON.stringify(jsonBody));
                    formData.append('animationZip', content, `${this.getProjectFilename(projectData.title, projectTitleInitialState)}`);
                    this.props.onCloneAssignmentProject(formData).then(response => {
                        // console.log('PROJECT SAVED', response);
                        const userId = (this.props.userData) ? this.props.userData.userId : 0;
                        this.props.onReceivedProjectTitle(projectData.title);
                        this.props.onCreatedProject(response.data.projectId.toString(), projectData, userId.toString(), this.props.loadingState);
                        this.callbackLoadAssignmentProject();
                    }).catch(err => {
                        console.log('PROJECT SAVING ERROR', err);
                        this.props.onError(err);
                        this.props.onCloseLoadingProject();
                    });
                });
            });
        }
        getProjectFilename(curTitle, defaultTitle) {
            // let filenameTitle = curTitle;
            // if (!filenameTitle || filenameTitle.length === 0) {
            //     filenameTitle = defaultTitle;
            // }
            // return `${filenameTitle.substring(0, 100)}.sb3`;
            return `${Date.now().toString()}-animaciones.sb3`;
        };
        cloneAssignmentProject(projectDetails) {
            this.props.onCreateProject();
            this.loadEducabotProject(projectDetails, this.callBackCloneAssignmentProject);
        }
        loadEducabotProjectSuccess(item, callBack = null, projectData = null, loadingEmptyProject = false) {
            this.props.vm.loadProject(projectData)
                .then(() => {
                    if (loadingEmptyProject && this.props.userData.userId === this.props.assignmentData.userId) {
                        this.props.onShowAssignmentWellcomeAlert();
                    }
                    this.props.onReceivedProjectTitle(item.title);
                    if (callBack) {
                        callBack();
                    } else {
                        this.props.onDoneLoadingProject(item.id.toString(), item, item.userId.toString());
                        this.props.onCloseLoadingProject();
                    }
                })
                .catch(error => {
                    console.log('ERROR LOADING PROJECT', error);
                    this.props.onShowLoadingErrorAlert();
                    this.props.onCloseLoadingProject();
                });
        }
        loadEducabotProject(item, callBack = null) {
            if (item.boardType && item.boardType === 'animations') {
                this.props.onStartFetchingEducabot();
                this.props.onOpenLoadingProject();

                if (item.jsonBody.projectZipFile) {
                    fetch(
                        `${routes.userProjectsUri}/animations/proxy/${encodeURIComponent(item.jsonBody.projectZipFile)}`,
                        // `${routes.userProjectsUri}/animations/proxy/${encodeURIComponent('https://educablocks-staging-space-v1.sfo2.digitaloceanspaces.com/animaciones/projects/7/1605112009502-animaciones.sb3')}`,
                        {
                            method: 'GET',
                            headers: {
                                "Content-type": "application/octet-stream"
                            },
                        },
                    ).then(result => {
                        // console.log('SB3 FILE', result);
                        return result.arrayBuffer();
                    }).then(response => {
                        // console.log('SB3 FILE ARRAYBUFFER', response);
                        // this.props.vm.loadProject(response)
                        //     .then(() => {
                        //         this.props.onReceivedProjectTitle(item.title);
                        //         if (callBack) {
                        //             callBack();
                        //         } else {
                        //             this.props.onDoneLoadingProject(item.id.toString(), item, item.userId.toString());
                        //             this.props.onCloseLoadingProject();
                        //         }
                        //     })
                        //     .catch(error => {
                        //         console.log('ERROR LOADING PROJECT', error);
                        //         this.props.onShowLoadingErrorAlert();
                        //         this.props.onCloseLoadingProject();
                        //         // alert(this.props.intl.formatMessage(messages.loadError)); // eslint-disable-line no-alert
                        //     });
                            this.loadEducabotProjectSuccess(item, callBack, response, false);
                    }).catch((error) => {
                        console.log('ERROR LOADING PROJECT', error);
                        // alert(this.props.intl.formatMessage(messages.loadError));
                        this.props.onShowLoadingErrorAlert();
                        this.props.onCloseLoadingProject();
                    });
                } else {
                    this.loadEducabotProjectSuccess(item, callBack, this.props.defaultProjectData, true);
                }
            } else {
                this.props.onShowNotAnimationProjectAlert();
                // this.props.onError(this.props.intl.formatMessage(messages.notAnimationProject));
            }
        }
        fetchProject(projectId, loadingState) {
            return storage
                .load(storage.AssetType.Project, projectId, storage.DataFormat.JSON)
                .then(projectAsset => {
                    if (projectAsset) {
                        this.props.onFetchedProjectData(projectAsset.data, loadingState);
                    } else {
                        // Treat failure to load as an error
                        // Throw to be caught by catch later on
                        throw new Error('Could not find project');
                    }
                })
                .catch(err => {
                    this.props.onError(err);
                    log.error(err);
                });
        }
        render() {
            const {
                /* eslint-disable no-unused-vars */
                assetHost,
                intl,
                isLoadingProject: isLoadingProjectProp,
                loadingState,
                onActivateTab,
                onError: onErrorProp,
                onFetchedProjectData: onFetchedProjectDataProp,
                onProjectUnchanged,
                projectHost,
                projectId,
                reduxProjectId,
                setProjectId: setProjectIdProp,
                onStartFetchingEducabot,
                onCreateProject,
                onCreatedProject,
                onDoneLoadingProject,
                onShowLoadingErrorAlert,
                onReceivedProjectTitle,
                onOpenLoadingProject,
                onCloseLoadingProject,
                onCloneAssignmentProject,
                onSaveProject,
                saveProjectSb3,
                assignmentData,
                assignmentId,
                projectDetails,
                isCreatingAssignmentEducabot,
                isCloningAssignmentEducabot,
                isLoadingAssignmentEducabot,
                isCreatingNew,
                isLoadingEducabot,
                isShowingWithoutId,
                onStartUpdateLoadingAssignment,
                userData,
                /* eslint-enable no-unused-vars */
                isFetchingWithId: isFetchingWithIdProp,
                ...componentProps
            } = this.props;
            return (
                <WrappedComponent
                    fetchingProject={isFetchingWithIdProp}
                    {...componentProps}
                />
            );
        }
    }
    ProjectFetcherComponent.propTypes = {
        assetHost: PropTypes.string,
        canSave: PropTypes.bool,
        intl: intlShape.isRequired,
        isCreatingNew: PropTypes.bool,
        isFetchingWithId: PropTypes.bool,
        isLoadingProject: PropTypes.bool,
        isShowingProject: PropTypes.bool,
        loadingState: PropTypes.oneOf(LoadingStates),
        onActivateTab: PropTypes.func,
        onError: PropTypes.func,
        onFetchedProjectData: PropTypes.func,
        onProjectUnchanged: PropTypes.func,
        projectHost: PropTypes.string,
        projectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        reduxProjectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        defaultProjectData: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        setProjectId: PropTypes.func,
        isLoadingEducabot: PropTypes.bool,
        isCloningAssignmentEducabot: PropTypes.bool,
        isCreatingAssignmentEducabot: PropTypes.bool,
        onOpenLoadingProject: PropTypes.func,
        onCloseLoadingProject: PropTypes.func,
        vm: PropTypes.instanceOf(VM).isRequired,
        onReceivedProjectTitle: PropTypes.func,
        onDoneLoadingProject: PropTypes.func,
        onShowLoadingErrorAlert: PropTypes.func,
        onShowNotAnimationProjectAlert: PropTypes.func,
        onShowAssignmentWellcomeAlert: PropTypes.func,
        projectDetails: PropTypes.object,
        assignmentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        assignmentData: PropTypes.object,
        onSaveProject: PropTypes.func,
        onCreateProject: PropTypes.func,
        onCloneAssignmentProject: PropTypes.func,
        onStartFetchingEducabot: PropTypes.func,
        isShowingWithoutId: PropTypes.bool,
        isLoadingEducabot: PropTypes.bool,
        userData: PropTypes.object,
        onStartUpdateLoadingAssignment: PropTypes.func,
    };
    ProjectFetcherComponent.defaultProps = {
        assetHost: 'https://assets.scratch.mit.edu',
        projectHost: 'https://projects.scratch.mit.edu'
    };

    const mapStateToProps = state => ({
        isLoadingEducabot: getIsLoadingEducabot(state.scratchGui.projectState.loadingState),
        isShowingWithoutId: getIsShowingWithoutId(state.scratchGui.projectState.loadingState),
        isCreatingNew: getIsCreatingNew(state.scratchGui.projectState.loadingState),
        isFetchingWithId: getIsFetchingWithId(state.scratchGui.projectState.loadingState),
        isLoadingProject: getIsLoading(state.scratchGui.projectState.loadingState),
        isShowingProject: getIsShowingProject(state.scratchGui.projectState.loadingState),
        isLoadingAssignmentEducabot: getIsLoadingAssignmentEducabot(state.scratchGui.projectState.loadingState),
        isCloningAssignmentEducabot: getIsCloningAssignmentEducabot(state.scratchGui.projectState.loadingState),
        isCreatingAssignmentEducabot: getIsCreatingAssignmentEducabot(state.scratchGui.projectState.loadingState),
        projectDetails: state.scratchGui.projectState.projectDetails || null,
        assignmentId: state.scratchGui.projectState.assignmentId || null,
        assignmentData: state.scratchGui.projectState.assignmentData || null,
        loadingState: state.scratchGui.projectState.loadingState,
        reduxProjectId: state.scratchGui.projectState.projectId,
        defaultProjectData: state.scratchGui.projectState.projectData,
        vm: state.scratchGui.vm,
        saveProjectSb3: state.scratchGui.vm.saveProjectSb3.bind(state.scratchGui.vm),
        onSaveProject: saveProjectDB,
        onCloneAssignmentProject: cloneAssignmentProject,
        userData: (state.scratchGui.sessionReducer.data.data) ? state.scratchGui.sessionReducer.data.data : null,
    });
    const mapDispatchToProps = dispatch => ({
        onActivateTab: tab => dispatch(activateTab(tab)),
        onError: error => dispatch(projectError(error)),
        onFetchedProjectData: (projectData, loadingState) =>
            dispatch(onFetchedProjectData(projectData, loadingState)),
        setProjectId: projectId => dispatch(setProjectId(projectId)),
        onProjectUnchanged: () => dispatch(setProjectUnchanged()),
        onOpenLoadingProject: () => dispatch(openLoadingProject()),
        onCloseLoadingProject: () => dispatch(closeLoadingProject()),
        onReceivedProjectTitle: title => dispatch(setProjectTitle(title)),
        onDoneLoadingProject: (id, details, userId) => dispatch(doneLoadingProject(id, details, userId)),
        onCreatedProject: (projectId, details, userId, loadingState) => dispatch(doneCreatingProject(projectId, details, userId, loadingState)),
        onShowLoadingErrorAlert: () => showAlertWithTimeout(dispatch, 'loadingError'),
        onShowNotAnimationProjectAlert: () => showAlertWithTimeout(dispatch, 'notAnimationProjectError'),
        onShowAssignmentWellcomeAlert: () => showAlertWithTimeout(dispatch, 'assignmentTeacherWellcome'),
        onCreateProject: () => dispatch(createProject()),
        onStartFetchingEducabot: () => dispatch(startFetchingEducabot()),
        onStartUpdateLoadingAssignment: (assignmentId, assignmentData, projectId, projectDetails, userId) => dispatch(startUpdateLoadingAssignment(assignmentId, assignmentData, projectId, projectDetails, userId)),
    });
    // Allow incoming props to override redux-provided props. Used to mock in tests.
    const mergeProps = (stateProps, dispatchProps, ownProps) => Object.assign(
        {}, stateProps, dispatchProps, ownProps
    );
    return injectIntl(connect(
        mapStateToProps,
        mapDispatchToProps,
        mergeProps
    )(ProjectFetcherComponent));
};

export {
    ProjectFetcherHOC as default
};
