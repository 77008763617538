const bindAll = require('lodash.bindall');
const classNames = require('classnames');
const React = require('react');
const PropTypes = require('prop-types');
const { injectIntl, intlShape } = require('react-intl');

import ProjectFlowDetailsStep from './project-flow-details-step.jsx';

require('./project-flow-steps.scss');

/*
 * Username step
 */
/* eslint-disable react/prefer-stateless-function, no-useless-constructor */
class DetailsStep extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, [
            'handleOpenEdit'
        ]);
    }
    // called after all validations pass with no errors
    handleOpenEdit(formData, formikBag) {
        this.props.onNextStep(formData);
    }
    render() {
        return (
            <ProjectFlowDetailsStep
                description={this.props.intl.formatMessage({
                    id: 'project.detailsStepDetailsProjectDescription'
                })}
                innerClassName="join-flow-inner-username-step"
                title={this.props.intl.formatMessage({id: 'project.projectDetails'})}
                onSubmit={(this.props.isMyProject) ? this.handleOpenEdit : () => {}}
                isMyProject={this.props.isMyProject}
                nextButton={this.props.intl.formatMessage({id: 'gui.menuBar.edit'})}
            >
                <div>
                    <div className="join-flow-input-title">
                        {this.props.intl.formatMessage({ id: 'project.detailsStepTitle' })}
                    </div>
                    <div>
                        {this.props.title}
                    </div>
                    <div className="join-flow-password-section"></div>
                    <div className="join-flow-input-title">
                        {this.props.intl.formatMessage({ id: 'project.detailsStepDescription' })}
                    </div>
                    <div className="join-flow-input-description">
                        {this.props.description}
                    </div>
                    <div className="join-flow-password-section"></div>
                    {(this.props.imageUrl !== '' && this.props.imageUrl) ? (
                        <div>
                            <img
                                width={'100%'}
                                src={this.props.imageUrl}
                            />
                        </div>
                    ) : null}
                </div>
            </ProjectFlowDetailsStep>
        );
    }
}

DetailsStep.propTypes = {
    intl: intlShape,
    onNextStep: PropTypes.func,
    title: PropTypes.string,
    description: PropTypes.string,
    imageUrl: PropTypes.string,
    isLibrary: PropTypes.bool,
    isPublic: PropTypes.bool,
    amIContenidistaGlobal: PropTypes.bool,
    isMyProject: PropTypes.bool,
};

const IntlDetailsStep = injectIntl(DetailsStep);

export default IntlDetailsStep;
