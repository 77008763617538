const PropTypes = require('prop-types');
const React = require('react');
const connect = require('react-redux').connect;

// const Login = require('./login.jsx');
import Login from './login.jsx';

require('./login-dropdown.scss');

const ConnectedLogin = ({
    error,
    onLogIn
}) => (
    <Login
        error={error}
        key="login-dropdown-presentation"
        onLogIn={onLogIn}
    />
);

ConnectedLogin.propTypes = {
    error: PropTypes.string,
    onLogIn: PropTypes.func
};

const mapStateToProps = (state, ownProps) => {
    // console.log(state, ownProps);
    return {
        error: ownProps.error || ''
    }
};

const mapDispatchToProps = () => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ConnectedLogin);
// module.exports = connect(
//     mapStateToProps,
//     mapDispatchToProps
// )(ConnectedLogin);
