const bindAll = require('lodash.bindall');
const FormattedMessage = require('react-intl').FormattedMessage;
const PropTypes = require('prop-types');
const React = require('react');

import Form from '../forms/form.jsx';
import Input from '../forms/input.jsx';
import Button from '../forms/button.jsx';
import Spinner from '../spinner/spinner.jsx';
import FlexRow from '../flex-row/flex-row.jsx';
// const Form = require('../forms/form.jsx');
// const Input = require('../forms/input.jsx');
// const Button = require('../forms/button.jsx');
// const Spinner = require('../spinner/spinner.jsx');
// const FlexRow = require('../flex-row/flex-row.jsx');

require('./login.scss');

class Login extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, [
            'handleSubmit',
            'changeField'
        ]);
        this.state = {
            waiting: false,
            formData: {
                hidden: true,
            }
        };
    }
    changeField(field, e) {
        const { formData } = this.state;
        formData[field] = e.target.value;
        this.setState({ formData });
    }
    showPass() {
        const { formData } = this.state;
        formData.hidden = !formData.hidden;
        this.setState({ formData });
    }
    handleSubmit() {
        const { formData } = this.state;
        this.setState({ waiting: true });
        this.props.onLogIn(formData, () => {
            this.setState({ waiting: false });
        });
    }
    render() {
        const { formData } = this.state;
        let error;
        if (this.props.error) {
            error = <div className="error">{this.props.error}</div>;
        }
        return (
            <div className="login">
                <Form onSubmit={this.handleSubmit}>
                    <label
                        htmlFor="email"
                        key="emailLabel"
                    >
                        <FormattedMessage id="general.username" />
                    </label>
                    <Input
                        required
                        key="emailKey"
                        maxLength="100"
                        name="email"
                        type="text"
                        onChange={(e) => this.changeField('email', e)}
                    />
                    <label
                        htmlFor="password"
                        key="passwordLabel"
                    >
                        <FormattedMessage id="general.password" />
                    </label>
                    <Input
                        required
                        key="passwordKey"
                        name="password"
                        type={formData.hidden ? "password" : "text"}
                        onChange={(e) => this.changeField('password', e)}
                    />
                    <FlexRow className="submit-row">
                        {this.state.waiting ? [
                            <Button
                                className="submit-button"
                                disabled="disabled"
                                key="submitButton"
                                type="submit"
                            >
                                <Spinner
                                    className="spinner"
                                    color="blue"
                                />
                            </Button>
                        ] : [
                                <Button
                                    className="submit-button white"
                                    key="submitButton"
                                    type="submit"
                                >
                                    <FormattedMessage id="general.signIn" />
                                </Button>
                            ]}
                        {/* <a
                            href="/accounts/password_reset/"
                            key="passwordResetLink"
                        >
                            <FormattedMessage id="login.needHelp" />
                        </a> */}
                    </FlexRow>
                    {error}
                </Form>
            </div>
        );
    }
}

Login.propTypes = {
    error: PropTypes.string,
    onLogIn: PropTypes.func
};

// module.exports = Login;
export default Login;