const bindAll = require('lodash.bindall');
const connect = require('react-redux').connect;
const defaults = require('lodash.defaultsdeep');
const PropTypes = require('prop-types');
const React = require('react');

import { saveProjectDB, updateProjectDB, doSaveProjectSb3 } from '../../lib/educabot-api';
import {
    openSaveProjectMenu,
    closeDetailsProjectMenu,
    remixProjectOpenState,
    detailsProjectOpenState
} from '../../reducers/menus';
import {
    projectTitleInitialState,
    setProjectTitle
} from '../../reducers/project-title';

import {
    doneCreatingProject,
    projectError,
    createProject,
    getIsAssignmentProjectDeliverable
} from '../../reducers/project-state';

// const api = require('../../lib/api');
// const injectIntl = require('../../lib/intl.jsx').injectIntl;
const injectIntl = require('react-intl').injectIntl;
// const intlShape = require('../../lib/intl.jsx').intlShape;
const intlShape = require('react-intl').intlShape;
// const sessionActions = require('../../redux/session.js');
const validate = require('../../lib/validate');

import Progression from '../progression/progression.jsx';
import DetailsStep from './details-step.jsx';
import DetailsEditStep from './details-edit-step.jsx';
import WelcomeStep from './welcome-step.jsx';
import SavingErrorStep from './saving-error-step.jsx';
// import UsernameStep from './username-step.jsx';
// import NameStep from './name-step.jsx';
// import WelcomeStep from './welcome-step.jsx';

class ProjectFlow extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'handleAdvanceStep',
            'handleErrorNext',
            'handlePrepareToSave',
            'handlePrepareToEdit',
            'handleSaveResponse',
            'handleSubmitProject',
            'saveProject'
        ]);
        this.initialState = {
            numAttempts: 0,
            formData: {},
            projectSavingError: null,
            step: 0,
            waiting: false
        };
        // it's ok to set state by reference, because state is treated as immutable,
        // so any changes to its fields will result in a new state which does not
        // reference its past fields
        this.state = this.initialState;
    }
    canTryAgain () {
        return (this.state.projectSavingError.errorAllowsTryAgain && this.state.numAttempts <= 1);
    }
    handlePrepareToEdit(newFormData) {
        this.props.onOpenSaveProject();
        this.props.onRequestCloseDetailsProject();
    }
    handlePrepareToSave (newFormData) {
        newFormData = newFormData || {};
        const newState = {
            formData: defaults({}, newFormData, this.state.formData)
        };
        this.setState(newState, () => {
            this.handleSubmitProject(this.state.formData);
        });
    }
    getErrorsFromResponse (res) {
        const errorsFromResponse = [];
        if (res.status === 'ERROR' && res.message) {
            const responseBodyErrors = res.message;
            if (responseBodyErrors) {
                errorsFromResponse.push({fieldName: 'title', errorStr: responseBodyErrors});
            }
        }
        return errorsFromResponse;
    }
    getCustomErrMsg (errorsFromResponse) {
        if (!errorsFromResponse || errorsFromResponse.length === 0) return null;
        let customErrMsg = '';
        // body can include zero or more error objects. Here we assemble
        // all of them into a single string, customErrMsg.
        errorsFromResponse.forEach(errorFromResponse => {
            if (customErrMsg.length) customErrMsg += '; ';
            customErrMsg += `${errorFromResponse.fieldName}: ${errorFromResponse.errorStr}`;
        });
        const problemsStr = this.props.intl.formatMessage({id: 'registration.problemsAre'});
        return `${problemsStr}: "${customErrMsg}"`;
    }
    savingIsSuccessful (res) {
        console.log('RESPONSE:::', res);
        return !!(!res || res.status !== 'ERROR');
    }
    getProjectFilename(curTitle, defaultTitle) {
        // let filenameTitle = curTitle;
        // if (!filenameTitle || filenameTitle.length === 0) {
        //     filenameTitle = defaultTitle;
        // }
        // return `${filenameTitle.substring(0, 100)}.sb3`;
        return `${Date.now().toString()}-animaciones.sb3`;
    };
    handleSaveResponse (res) {
        // console.log('PROJECT SAVING RESPONSE:::', res);
        this.setState({
            numAttempts: this.state.numAttempts + 1
        }, () => {
            const success = this.savingIsSuccessful(res);
            if (success && res.id) {
                this.setState({
                    step: this.state.step + 1,
                    waiting: false
                });
                // this.props.onCompleteProjectSaving();
                this.props.onReceivedProjectTitle(res.title);
                // this.props.onCreatedProject(res.id.toString(), res.userId.toString(), this.props.loadingState);
                this.props.onCreatedProject(res.id.toString(), res, res.userId.toString(), this.props.loadingState);
                return;
            }
            // now we know there was a problem with the registration content.
            // If the server provided us info on why registration failed,
            // build a summary explanation string
            let errorMsg = null;
            const errorsFromResponse = this.getErrorsFromResponse(res);
            // if there was exactly one error, check if we have a pre-written message
            // about that precise error
            if (errorsFromResponse.length === 1) {
                const singleErrMsgId = validate.responseErrorMsg(
                    errorsFromResponse[0].fieldName,
                    errorsFromResponse[0].errorStr
                );
                if (singleErrMsgId) { // one error that we have a predefined explanation string for
                    errorMsg = this.props.intl.formatMessage({id: singleErrMsgId});
                }
            }
            // if we have more than one error, build a custom message with all of the
            // server-provided error messages
            if (!errorMsg && errorsFromResponse.length > 0) {
                errorMsg = this.getCustomErrMsg(errorsFromResponse);
            }
            this.setState({
                projectSavingError: {
                    errorAllowsTryAgain: false,
                    errorMsg: errorMsg
                },
                waiting: false
            });
        });
    }
    saveProject (projectData, callback) {

        this.props.doSaveProjectSb3(this.props.vm, projectData, this.props.userData.userId, this.props.remixProjectOpenState, callback).then(result => {
            console.log(result);
        }).catch(err =>  {
            this.props.onProjectError(err);
            callback(err);
        });
    }
    handleSubmitProject (formData) {
        this.setState({
            projectSavingError: null, // clear any existing error
            waiting: true
        }, () => {
            this.props.onCreateProject();
            this.saveProject({
                id: this.props.projectDetails.id || null,
                userId: this.props.projectDetails.userId || 0,
                boardType: this.props.projectDetails.boardType || 'animations',
                jsonBody: this.props.projectDetails.jsonBody || {},
                title: formData.title,
                description: formData.description,
                isLibrary: formData.isLibrary,
                isPublic: formData.isPublic,

            }, this.handleSaveResponse);
        });
    }
    handleAdvanceStep (newFormData) {
        newFormData = newFormData || {};
        this.setState({
            formData: defaults({}, newFormData, this.state.formData),
            step: this.state.step + 1
        });
    }
    handleErrorNext () {
        if (this.canTryAgain()) {
            this.handleSubmitProject(this.state.formData);
        } else {
            this.resetState();
        }
    }
    resetState () {
        this.setState(this.initialState);
    }
    sendAnalytics (path) {
        const gaID = window.GA_ID;
        if (!window.ga) {
            return;
        }
        window.ga('send', {
            hitType: 'pageview',
            page: path,
            tid: gaID
        });
    }

    render () {
        return (
            <React.Fragment>
                {(this.props.detailsProjectOpenState) ? (
                    <DetailsStep
                        title={this.props.projectTitle}
                        description={(this.props.projectDetails) ? this.props.projectDetails.description : ''}
                        isLibrary={(this.props.projectDetails) ? this.props.projectDetails.isLibrary : false}
                        isPublic={(this.props.projectDetails) ? this.props.projectDetails.isPublic : false}
                        imageUrl={(this.props.projectDetails) ? this.props.projectDetails.imageUrl : ''}
                        amIContenidistaGlobal={this.props.amIContenidistaGlobal}
                        isMyProject={this.props.isMyProject}
                        onNextStep={this.handlePrepareToEdit}
                    />
                ) : (
                    (this.state.projectSavingError) ? (
                        <SavingErrorStep
                            canTryAgain={this.canTryAgain()}
                            errorMsg={this.state.projectSavingError.errorMsg}
                            sendAnalytics={this.sendAnalytics}
                            /* eslint-disable react/jsx-no-bind */
                            onSubmit={this.handleErrorNext}
                            /* eslint-enable react/jsx-no-bind */
                        />
                    ) : (
                        <Progression step={this.state.step}>
                            <DetailsEditStep
                                waiting={this.state.waiting}
                                onNextStep={this.handlePrepareToSave}
                                title={this.props.projectTitle}
                                description={(this.props.projectDetails) ? this.props.projectDetails.description : ''}
                                isLibrary={(this.props.projectDetails) ? this.props.projectDetails.isLibrary : false}
                                isPublic={(this.props.projectDetails) ? this.props.projectDetails.isPublic : false}
                                amIContenidistaGlobal={this.props.amIContenidistaGlobal}
                            />
                            <WelcomeStep
                                createProjectOnComplete={this.props.createProjectOnComplete}
                                sendAnalytics={this.sendAnalytics}
                                username={this.state.formData.firstName || this.state.formData.username}
                                onNextStep={this.props.onCompleteProjectSaving}
                            />
                        </Progression>
                    )
            )}
            </React.Fragment>
        );
    }
}


ProjectFlow.propTypes = {
    createProjectOnComplete: PropTypes.bool,
    intl: intlShape,
    onCompleteProjectSaving: PropTypes.func,
    refreshSessionWithRetry: PropTypes.func,
    onSaveProject: PropTypes.func,
    onUpdateProject: PropTypes.func,
    userData: PropTypes.object,
    onCreatedProject: PropTypes.func,
    onCreateProject: PropTypes.func,
    onProjectError: PropTypes.func,
    onReceivedProjectTitle: PropTypes.func,
    amIContenidistaGlobal: PropTypes.bool,
    isMyProject: PropTypes.bool,
    projectDetails: PropTypes.object,
    detailsProjectOpenState: PropTypes.bool,
    remixProjectOpenState: PropTypes.bool,
    onOpenSaveProject: PropTypes.func,
    onRequestCloseDetailsProject: PropTypes.func,
    doSaveProjectSb3: PropTypes.func,
};

ProjectFlow.defaultProps = {
    userData: {},
    amIContenidistaGlobal: false,
    isMyProject: false,
    detailsProjectOpenState: false,
    remixProjectOpenState: false,
};

const IntlProjectFlow = injectIntl(ProjectFlow);

const mapStateToProps = state => {
    const loadingState = state.scratchGui.projectState.loadingState;
    console.log('NEWPROPS', state.scratchGui);
    const userData = state.scratchGui.sessionReducer.data.data;
    const amIContenidistaGlobal = (userData.roles.names.includes('Contenidista Global') || userData.roles.names.includes('Administrador'));
    const isMyProject = (
        getIsAssignmentProjectDeliverable(state.scratchGui) &&
        userData &&
        state.scratchGui.projectState.projectUserId &&
        state.scratchGui.projectState.projectUserId.toString() === userData.userId.toString()
    ) ? true : false;

    return {
        loadingState: loadingState,
        vm: state.scratchGui.vm,
        saveProjectSb3: state.scratchGui.vm.saveProjectSb3.bind(state.scratchGui.vm),
        projectTitle: state.scratchGui.projectTitle || projectTitleInitialState,
        projectDetails: state.scratchGui.projectState.projectDetails || {},
        onSaveProject: saveProjectDB,
        onUpdateProject: updateProjectDB,
        userData: userData,
        amIContenidistaGlobal: amIContenidistaGlobal,
        isMyProject: isMyProject,
        detailsProjectOpenState: detailsProjectOpenState(state),
        remixProjectOpenState: remixProjectOpenState(state),
        doSaveProjectSb3: doSaveProjectSb3,
    }
};


const mapDispatchToProps = dispatch => ({
    onCreatedProject: (projectId, details, userId, loadingState) => dispatch(doneCreatingProject(projectId, details, userId, loadingState)),
    onCreateProject: () => dispatch(createProject()),
    onProjectError: error => dispatch(projectError(error)),
    onReceivedProjectTitle: title => dispatch(setProjectTitle(title)),
    onOpenSaveProject: () => dispatch(openSaveProjectMenu()),
    onRequestCloseDetailsProject: () => dispatch(closeDetailsProjectMenu()),
});

// Allow incoming props to override redux-provided props. Used to mock in tests.
const mergeProps = (stateProps, dispatchProps, ownProps) => {
    // console.log('----------', stateProps, dispatchProps, ownProps);
    return Object.assign({}, stateProps, dispatchProps, ownProps);
};

const ConnectedProjectFlow = connect(
    // () => ({}),
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(IntlProjectFlow);

export default ConnectedProjectFlow;
