const PropTypes = require('prop-types');
const React = require('react');
const Modal = require('../base/modal.jsx');
import ProjectFlow from '../../project-flow/project-flow.jsx';

require('./modal.scss');

const ProjectModal = ({
    createProjectOnComplete,
    onCompleteProjectSaving,
    onRequestClose,
    ...modalProps
}) => (
    <Modal
        isOpen
        showCloseButton
        useStandardSizes
        className="mod-join"
        shouldCloseOnOverlayClick={false}
        onRequestClose={onRequestClose}
        {...modalProps}
    >
        <ProjectFlow
            createProjectOnComplete={createProjectOnComplete}
            onCompleteProjectSaving={onCompleteProjectSaving}
        />
    </Modal>
);

ProjectModal.propTypes = {
    createProjectOnComplete: PropTypes.bool,
    onCompleteProjectSaving: PropTypes.func,
    onRequestClose: PropTypes.func,
    showCloseButton: PropTypes.bool,
};

export default ProjectModal;
