/**
 * @fileoverview
 * Utility function to detect assignment id from query paramenter on the URL.
 */

/**
 * Check if there's a assignment id provided as a query parameter in the URL.
 * Return the corresponding assignment id or null if not found.
 * @param {object} queryParams the results of parsing the query string
 * @return {string} The ID of the requested assignment or null if no assignment was
 * requested or found.
 */
const detectAssignmentId = queryParams => {
    const assignmentID = Array.isArray(queryParams.actividad) ?
        queryParams.actividad[0] :
        queryParams.actividad;
    if (typeof assignmentID === 'undefined') return null;
    return assignmentID;
};

export {
    detectAssignmentId
};
