const bindAll = require('lodash.bindall');
const classNames = require('classnames');
const React = require('react');
const PropTypes = require('prop-types');
import {Formik} from 'formik';
const {injectIntl, intlShape} = require('react-intl');

const validate = require('../../lib/validate');
import FormikInput from '../../components/formik-forms/formik-input.jsx';
import FormikCheckbox from '../../components/formik-forms/formik-checkbox.jsx';
import ProjectFlowStep from './project-flow-step.jsx';

require('./project-flow-steps.scss');

/*
 * Username step
 */
/* eslint-disable react/prefer-stateless-function, no-useless-constructor */
class DetailsEditStep extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'handleFocused',
            'handleSetTitleRef',
            'handleValidSubmit',
            'validateTitleIfPresent',
            'validateDescriptionIfPresent',
            'validateForm'
        ]);
        this.state = {
            focused: null
        };
    }
    componentDidMount () {
        // automatically start with focus on title field
        if (this.titleInput) this.titleInput.focus();
    }
    // track the currently focused input field, to determine whether each field should
    // display a tooltip. (We only display it if a field is focused and has never been touched.)
    handleSetTitleRef (titleInputRef) {
        this.titleInput = titleInputRef;
    }
    handleFocused (fieldName) {
        this.setState({focused: fieldName});
    }
    validateTitleIfPresent (title) {
        const localResult = validate.validateTitle(title);
        if (localResult.valid) return null;
        return this.props.intl.formatMessage({id: localResult.errMsgId});
    }
    validateDescriptionIfPresent (desc) {
        const localResult = validate.validateDescription(desc);
        if (localResult.valid) return null;
        return this.props.intl.formatMessage({id: localResult.errMsgId});
    }
    // called asynchonously when form submit is initially requested,
    // along with all of the individual field validation functions
    validateForm (values) {
        // in addition to field-level username/password validations, we need to additionally
        // check that these values aren't blank.
        const errors = {};
        const titleResult = validate.validateTitle(values.title);
        if (!titleResult.valid) {
            errors.title = this.props.intl.formatMessage({id: titleResult.errMsgId});
        }
        const descResult = validate.validateDescription(values.description);
        if (!descResult.valid) {
            errors.description = this.props.intl.formatMessage({id: descResult.errMsgId});
        }
        return errors;
    }
    // called after all validations pass with no errors
    handleValidSubmit (formData, formikBag) {
        formikBag.setSubmitting(false); // formik makes us do this ourselves
        this.props.onNextStep(formData);
    }
    render () {
        return (
            <Formik
                initialValues={{
                    title: this.props.title,
                    description: this.props.description,
                    isLibrary: this.props.amIContenidistaGlobal ? this.props.isLibrary || false : false,
                    isPublic: this.props.isPublic || false
                }}
                validate={this.validateForm}
                validateOnBlur={false}
                validateOnChange={false}
                onSubmit={this.handleValidSubmit}
            >
                {props => {
                    const {
                        errors,
                        handleSubmit,
                        isSubmitting,
                        setFieldError,
                        setFieldTouched,
                        setFieldValue,
                        touched,
                        validateField,
                        values
                    } = props;
                    return (
                        <ProjectFlowStep
                            description={this.props.intl.formatMessage({
                                id: 'project.detailsStepSaveProjectDescription'
                            })}
                            innerClassName="join-flow-inner-username-step"
                            title={this.props.intl.formatMessage({id: 'project.saveProject'})}
                            onSubmit={handleSubmit}
                            waiting={isSubmitting || this.props.waiting}
                        >
                            <div>
                                <div className="join-flow-input-title">
                                    {this.props.intl.formatMessage({id: 'project.detailsStepTitle'})}
                                </div>
                                <FormikInput
                                    autoCapitalize="off"
                                    autoComplete="off"
                                    autoCorrect="off"
                                    className={classNames(
                                        'join-flow-input',
                                        'join-flow-input-tall',
                                        {fail: errors.title}
                                    )}
                                    error={errors.title}
                                    id="title"
                                    name="title"
                                    placeholder={this.props.intl.formatMessage({id: 'project.title'})}
                                    type="text"
                                    validate={this.validateTitleIfPresent}
                                    validationClassName="validation-full-width-input"
                                    /* eslint-disable react/jsx-no-bind */
                                    onBlur={() => validateField('title')}
                                    onChange={e => {
                                        setFieldValue('title', e.target.value.substring(0, 32));
                                        setFieldTouched('title');
                                        setFieldError('title', null);
                                    }}
                                    /* eslint-enable react/jsx-no-bind */
                                    onSetRef={this.handleSetTitleRef}
                                />
                                <div className="join-flow-input-title">
                                    {this.props.intl.formatMessage({id: 'project.detailsStepDescription'})}
                                </div>
                                <FormikInput
                                    autoCapitalize="off"
                                    autoComplete="off"
                                    autoCorrect="off"
                                    component="textarea"
                                    className={classNames(
                                        'join-flow-input',
                                        'join-flow-input-description-area',
                                        {fail: errors.description}
                                    )}
                                    error={errors.description}
                                    id="description"
                                    name="description"
                                    placeholder={this.props.intl.formatMessage({id: 'project.description'})}
                                    type="text"
                                    validate={this.validateDescriptionIfPresent}
                                    validationClassName="validation-full-width-input"
                                    /* eslint-disable react/jsx-no-bind */
                                    onBlur={() => validateField('description')}
                                    onChange={e => {
                                        setFieldValue('description', e.target.value.substring(0, 1000));
                                        setFieldTouched('description');
                                        setFieldError('description', null);
                                    }}
                                    onFocus={() => this.handleFocused('description')}
                                />
                                {(this.props.amIContenidistaGlobal) && (
                                    <div className="join-flow-password-section">
                                        <FormikCheckbox
                                            id="isLibrary"
                                            label={this.props.intl.formatMessage({id: 'project.isLibrary'})}
                                            labelClassName="join-flow-input-title"
                                            name="isLibrary"
                                        />
                                    </div>
                                )}
                                <div className="join-flow-password-section">
                                    <FormikCheckbox
                                        id="isPublic"
                                        label={this.props.intl.formatMessage({id: 'project.isPublic'})}
                                        labelClassName="join-flow-input-title"
                                        name="isPublic"
                                    />
                                </div>
                            </div>
                        </ProjectFlowStep>
                    );
                }}
            </Formik>
        );
    }
}

DetailsEditStep.propTypes = {
    intl: intlShape,
    onNextStep: PropTypes.func,
    title: PropTypes.string,
    description: PropTypes.string,
    isLibrary: PropTypes.bool,
    isPublic: PropTypes.bool,
    amIContenidistaGlobal: PropTypes.bool,
    waiting: PropTypes.bool,
};

const IntlDetailsEditStep = injectIntl(DetailsEditStep);

export default IntlDetailsEditStep;
