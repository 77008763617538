import React, { useEffect, useState } from 'react';
import classes from './paginator.css';
import { FormattedMessage } from 'react-intl';

const Paginator = (props) => {
  const { onPageChange, count, limit, offset } = props;

  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);

  useEffect(() => {
    if (limit) {
      setPage(0);
      setPageCount(Math.ceil(count / limit));
    }
  }, [count, limit])

  const handlePageChange = (direction = 'up') => {
    let newPage = page;
    if (direction === 'up' && page + 1 < pageCount) {
      newPage = page + 1;
    }
    if (direction === 'down' && page > 0) {
      newPage = page - 1;
    }
    if (newPage !== page) {
      setPage(newPage);
      onPageChange(newPage);
    }
  };

  return (
    pageCount > 1 && (
      <div className={classes.paginator}>
        <div>{`${offset+1}-${Math.min(offset+limit, count)}`}</div>
        <div>&nbsp;<FormattedMessage id="project.pagination.of" defaultMessage="de" />&nbsp;</div>
        <div>{`${count}`}</div>
        <div
          className={`${classes.paginatorArrow} ${(page <= 0) ? classes.paginatorDisabled : ''}`}
          onClick={() => { handlePageChange('down') }}
        >
          <img src="../../../static/assets/arrow-left.svg" alt="" />
        </div>
        <div
          className={`${classes.paginatorArrow} ${(page + 1 >= pageCount) ? classes.paginatorDisabled : ''}`}
          onClick={() => { handlePageChange('up') }}
        >
          <img src="../../../static/assets/arrow-right.svg" alt="" />
        </div>
      </div>
    )
  );
};

Paginator.defaultProps = {
  onPageChange: () => {},
  count: 0,
  offset: 0,
  limit: 20,
};

export default Paginator;