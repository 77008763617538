exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* #E5F0FF */ /* #E9F1FC */ /* #D9E3F2 */ /* 90% transparent version of motion-primary */ /* #FFFFFF */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 15% transparent version of black */ /* 15% transparent version of black */ /* #575E75 */ /* #4F36D6 */ /* #3373CC */ /* 35% transparent version of motion-primary */ /* 15% transparent version of motion-primary */ /* #FF661A */ /* #E64D00 */ /* #CF63CF */ /* #BD42BD */ /* #FFAB19 */ /* #FF8C1A */ /* #0FBD8C */ /* #0FBD8C */ /* #FF8C1A */ /* #FFB366 */ /* #FF8C1A */ /* #0FBD8C */ /* #0B8E69 */ /* 35% transparent version of extensions-primary */ /* opaque version of extensions-transparent, on white bg */ /* lighter than motion-primary */ .community-button_community-button_2Lo_g {\n    -webkit-box-shadow: 0 0 0 1px #D8DBE8;\n            box-shadow: 0 0 0 1px #D8DBE8;\n} .community-button_community-button-icon_1IFvv {\n    height: 1.25rem;\n}\n", ""]);

// exports
exports.locals = {
	"community-button": "community-button_community-button_2Lo_g",
	"communityButton": "community-button_community-button_2Lo_g",
	"community-button-icon": "community-button_community-button-icon_1IFvv",
	"communityButtonIcon": "community-button_community-button-icon_1IFvv"
};