import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {projectTitleInitialState} from '../reducers/project-title';

import { updateProjectDB } from '../lib/educabot-api';
import {
    showAlertWithTimeout,
} from '../reducers/alerts';
import {
    projectError,
    doneLoadingProject,
} from '../reducers/project-state';

// import downloadBlob from '../lib/download-blob';
/**
 * Project saver component passes a saveProject function to its child.
 * It expects this child to be a function with the signature
 *     function (saveProject, props) {}
 * The component can then be used to attach project saving functionality
 * to any other component:
 *
 * <EducabotSaver>{(saveProject, props) => (
 *     <MyCoolComponent
 *         onClick={saveProject}
 *         {...props}
 *     />
 * )}</EducabotSaver>
 */
class EducabotSaver extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'saveProject'
        ]);
    }
    getProjectFilename(curTitle, defaultTitle) {
        // let filenameTitle = curTitle;
        // if (!filenameTitle || filenameTitle.length === 0) {
        //     filenameTitle = defaultTitle;
        // }
        // return `${filenameTitle.substring(0, 100)}.sb3`;
        return `${this.props.projectId}-animaciones.sb3`;
    };
    saveProject () {
        this.props.onShowSavingAlert();
        if (this.props.projectId) {
            this.props.vm.postIOData('video', {forceTransparentPreview: true});
            this.props.vm.renderer.requestSnapshot(canvasDataURI => {
                this.props.vm.postIOData('video', {forceTransparentPreview: false});

                this.props.saveProjectSb3().then(content => {
                    const userId = (this.props.userData) ? this.props.userData.userId : 0;
                    var formData = new FormData();
                    formData.append('title', this.props.projectTitle);
                    formData.append('boardType', 'animations');
                    const jsonBody = (this.props.projectDetails && this.props.projectDetails.jsonBody) ? this.props.projectDetails.jsonBody : {};
                    jsonBody.boardType = 'animations';
                    jsonBody.projectZipFile = '';
                    jsonBody.projectImg = canvasDataURI;
                    if (!jsonBody.assignmentUserRole) {
                        jsonBody.assignmentUserRole = 'OWNER';
                    }
                    formData.append('jsonBody', JSON.stringify(jsonBody));
                    formData.append('animationZip', content, `${this.getProjectFilename(this.props.projectTitle, projectTitleInitialState)}`);

                    this.props.onSaveProject(this.props.projectId, formData).then(response => {
                        this.props.onSaveSuccess(this.props.projectId, response, this.props.userData.userId.toString());
                        this.props.onShowSaveSuccessAlert();

                        if (this.props.onSaveFinished) {
                            this.props.onSaveFinished(this.props.loadingState);
                        }
                    }).catch(err => {
                        // console.log('PROJECT SAVING ERROR', err);
                        this.props.onProjectError();
                    });
                });
            });
        }
    }
    render () {
        const {
            children
        } = this.props;
        return children(
            this.props.className,
            this.saveProject
        );
    }
}


EducabotSaver.propTypes = {
    children: PropTypes.func,
    onSaveFinished: PropTypes.func,
    saveProjectSb3: PropTypes.func,
    onSaveProject: PropTypes.func,
    canvasImg: PropTypes.string,
    userData: PropTypes.object,
    onUpdatedProject: PropTypes.func,
    onSaveSuccess: PropTypes.func,
    projectDetails: PropTypes.object,
};
EducabotSaver.defaultProps = {
    className: '',
    canvasImg: '',
    userData: {}
};

const mapStateToProps = state => {
    return {
        loadingState: state.scratchGui.projectState.loadingState,
        projectId: state.scratchGui.projectState.projectId,
        projectUserId: state.scratchGui.projectState.projectUserId || 0,
        vm: state.scratchGui.vm,
        saveProjectSb3: state.scratchGui.vm.saveProjectSb3.bind(state.scratchGui.vm),
        projectTitle: state.scratchGui.projectTitle || projectTitleInitialState,
        onSaveProject: updateProjectDB,
        userData: state.scratchGui.sessionReducer.data.data,
        projectDetails: state.scratchGui.projectState.projectDetails || null,
    }
};

const mapDispatchToProps = dispatch => ({
    onProjectError: error => dispatch(projectError(error)),
    onShowSaveSuccessAlert: () => showAlertWithTimeout(dispatch, 'saveSuccess'),
    onShowSavingAlert: () => showAlertWithTimeout(dispatch, 'saving'),
    onSaveSuccess: (id, details, userId) => dispatch(doneLoadingProject(id, details, userId)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
    // () => ({}) // omit dispatch prop
)(EducabotSaver);
