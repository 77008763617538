import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';

import {
    closeCards,
    shrinkExpandCards,
    nextStep,
    prevStep,
    dragCard,
    startDrag,
    endDrag
} from '../reducers/project-details-card';
import { getIsShowingWithId, getIsAssignmentProjectDeliverable } from '../reducers/project-state';

import ProjectDetailsCardsComponent from '../components/project-details-cards/project-details-cards.jsx';
import {loadImageData} from '../lib/libraries/decks/translate-image.js';
import {notScratchDesktop} from '../lib/isScratchDesktop';
import { openSaveProjectMenu } from '../reducers/menus';

class ProjectDetailsCards extends React.Component {
    componentDidMount () {
        if (this.props.locale !== 'en') {
            loadImageData(this.props.locale);
        }
        
    }
    componentDidUpdate (prevProps) {
        if (this.props.locale !== prevProps.locale) {
            loadImageData(this.props.locale);
        }
    }
    render () {
        return (
            <ProjectDetailsCardsComponent {...this.props} />
        );
    }
}

ProjectDetailsCards.propTypes = {
    locale: PropTypes.string.isRequired,
    canSave: PropTypes.bool
};

const mapStateToProps = state => {
    let session = false;
    const sessionState = state.scratchGui.sessionReducer;
    if (sessionState.status === 'session_fetch' && sessionState.data.data.user) {
        session = sessionState.data.data;
    }
    
    return {
        visible: state.scratchGui.projectDetailsCards.visible,
        content: state.scratchGui.projectDetailsCards.content,
        step: state.scratchGui.projectDetailsCards.step,
        expanded: state.scratchGui.projectDetailsCards.expanded,
        x: state.scratchGui.projectDetailsCards.x,
        y: state.scratchGui.projectDetailsCards.y,
        isRtl: state.locales.isRtl,
        locale: state.locales.locale,
        dragging: state.scratchGui.projectDetailsCards.dragging,
        showVideos: notScratchDesktop(),
        isShowingWithId: getIsShowingWithId(state.scratchGui.projectState.loadingState),
        isAssignmentProjectDeliverable: getIsAssignmentProjectDeliverable(state.scratchGui),
        username: (session && session.email) ? session.email : 'Alumno Educabot',
    }
};

const mapDispatchToProps = dispatch => ({
    onCloseCards: () => dispatch(closeCards()),
    onShrinkExpandCards: () => dispatch(shrinkExpandCards()),
    onNextStep: () => dispatch(nextStep()),
    onPrevStep: () => dispatch(prevStep()),
    onDrag: (e_, data) => dispatch(dragCard(data.x, data.y)),
    onStartDrag: () => dispatch(startDrag()),
    onOpenEditForm:  () => {
        dispatch(closeCards())
        dispatch(openSaveProjectMenu())
    },
    onEndDrag: () => dispatch(endDrag())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProjectDetailsCards);
