import axios from 'axios';
import * as routes from '@educabot/educablocks-cosmos';


const saveProjectDB = (content) => {
    return new Promise((resolve, reject) => {
        axios.post(routes.userProjectsUri, content).then((response) => {
            resolve(response.data);
        }).catch((e) => {
            reject(e);
        });
    });
};

const updateProjectDB = (id, content) => {
    return new Promise((resolve, reject) => {
        axios.put(`${routes.userProjectsUri}/${id}`, content).then((response) => {
            resolve(response.data);
        }).catch((e) => {
            reject(e);
        });
    });
};

const deleteProjectDB = (id) => {
    return new Promise((resolve, reject) => {
        axios.delete(`${routes.userProjectsUri}/${id}`).then((response) => {
            resolve(response.data);
        }).catch((e) => {
            reject(e);
        });
    });
};


const getProjects = (limit = 20, offset = 0, searchParams = {}) => {
    return new Promise((resolve, reject) => {
        const params = `${searchParams.isPublic !== undefined ? `&isPublic=${searchParams.isPublic}` : ''}${searchParams.isLibrary !== undefined ? `&isLibrary=${searchParams.isLibrary}` : ''}${searchParams.search && searchParams.search !== '' ? `&search=${searchParams.search}` : ''}`;
        const view = (!searchParams.isPublic && !searchParams.isLibrary) ? 'animations-all' : 'animations'
        axios.get(`${routes.apiUri}/paged/projects?${searchParams.userId ? `userId=${searchParams.userId}&` : ''}limit=${limit}&offset=${offset}&view=${view}${params}`).then((response) => {
            resolve(response.data);
        }).catch((e) => {
            reject(e);
        });
    });
};

const getProjectById = (id) => {
    return new Promise((resolve, reject) => {
        axios.get(`${routes.projectsUri}/${id}`).then((response) => {
            if (response.data.error) {
                reject(response.data.error);
            } else {
                resolve(response.data);
            }
        }).catch((e) => {
            reject(e);
        });
    });
};

const getAssignmentSession = (id) => {
    return new Promise((resolve, reject) => {
        axios.get(`${routes.AssignmentSessionUri}/${id}`).then((response) => {
            resolve(response.data);
        }).catch((e) => {
            reject(e);
        });
    });
}

const cloneAssignmentProject = (projectData) => {
    return new Promise((resolve, reject) => {
        axios.post(`${routes.AssignmentSessionUri}/project`, projectData).then((response) => {
            resolve(response.data);
        }).catch((e) => {
            reject(e);
        });
    });
};


const updateAssignmentProjectDB = (assignmentSessionId, projectId, projectData) => {
    return new Promise((resolve, reject) => {
        axios.post(`${routes.AssignmentSessionUri}/${assignmentSessionId}/project/${projectId}`, projectData).then((response) => {
            resolve(response.data);
        }).catch((e) => {
            reject(e);
        });
    });
};


const doSaveProjectSb3 = (vm, projectData, userId = null, remixProjectOpenState = false, callback = null) => {
    return new Promise((resolve, reject) => {
        vm.postIOData('video', {forceTransparentPreview: true});
        vm.renderer.requestSnapshot(canvasDataURI => {
            vm.postIOData('video', {forceTransparentPreview: false});
            const saveProjectSb3 = vm.saveProjectSb3.bind(vm);
            saveProjectSb3().then(content => {
                const fileName = (projectData.id) ? `${projectData.id}-animaciones.sb3` : `${Date.now().toString()}-animaciones.sb3`;
                var formData = new FormData();
                formData.append('title', projectData.title);
                if (projectData.description)            formData.append('description',  projectData.description);
                if (projectData.assignmentSessionId)    formData.append('assignmentSessionId', projectData.assignmentSessionId);
                if (projectData.isLibrary)              formData.append('isLibrary',    projectData.isLibrary);
                if (projectData.isPublic)               formData.append('isPublic',     projectData.isPublic);
                if (projectData.canvasCss)              formData.append('canvasCss',    projectData.canvasCss);
                if (projectData.imageUrl)               formData.append('imageUrl',     projectData.imageUrl);
                formData.append('boardType', 'animations');
                formData.append('jsonBody', JSON.stringify({
                    boardType: 'animations',
                    projectZipFile: '',
                    projectImg: canvasDataURI,
                    assignmentUserRole: (projectData.jsonBody && projectData.jsonBody.assignmentUserRole) ? projectData.jsonBody.assignmentUserRole : 'OWNER',
                }));
                formData.append('animationZip', content, fileName);
                if (remixProjectOpenState || !projectData.id) {
                    saveProjectDB(formData).then(response => {
                        if (callback) {
                            callback(response);
                        } else {
                            resolve(response);
                        }
                    }).catch(err => {
                        if (callback) {
                            callback(err);
                        } else {
                            reject(err);
                        }
                    });
                } else if (projectData.id && userId && userId === projectData.userId) {
                    updateProjectDB(projectData.id, formData).then(response => {
                        if (callback) {
                            callback(response);
                        } else {
                            resolve(response);
                        }
                    }).catch(err => {
                        if (callback) {
                            callback(err);
                        } else {
                            reject(err);
                        }
                    });
                }
            });
        });
    });
};


export {
    saveProjectDB,
    updateProjectDB,
    deleteProjectDB,
    getProjects,
    getProjectById,
    getAssignmentSession,
    cloneAssignmentProject,
    updateAssignmentProjectDB,
    doSaveProjectSb3,
};
