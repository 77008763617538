import React from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import styles from './electron-autoupdater.css';
import PropTypes from 'prop-types';
// import { ipcRenderer } from 'electron';
// window.ipcRenderer = ipcRenderer;
// console.log('///////////////////////////////', window.ipcRenderer);

const messages = [
    {
        message: (
            <FormattedMessage
                defaultMessage="Creating blocks …"
                description="One of the loading messages"
                id="gui.loader.message1"
            />
        ),
        weight: 50
    }
];

class ElectronAutoupdaterComponent extends React.Component {
    constructor (props) {
        super(props);
        this.state = {};
    }

    componentDidMount () {

    }

    render () {
        let progress = {};
        window.ipcRenderer.on('updater-message', (event, arg) => {
        switch (arg) {
            case 'UPDATE_AVAILABLE':
            // there is an update
            const ele = document.querySelector('#electron-autoupdater');
            ele.classList.add('active');
            break;
            case 'ERROR':
            // error
            showError();
            break;
            case 'UPDATE_READY':
            // ready to install
            showInstall();
            break;
            default:
            // percentage sent
            progress = JSON.parse(arg);
            const barStatus = document.querySelector('#updater-progress-bar-status');
            const barStatusText = document.querySelector('#updater-progress-bar-status-text');
            const barStatusRemaining = document.querySelector('#updater-progress-remaining');
            if (barStatus) {
                barStatus.style.width = `${parseInt(progress.percent)}%`;
            }
            if (barStatusText) {
                barStatusText.innerHTML = `${parseInt(progress.percent)}%`;
            }
            if (barStatusRemaining) {
                barStatusRemaining.innerHTML = `${Math.round(progress.transferred / 1000000)} / ${Math.round(progress.total / 1000000)} Mb`;
            }
            break;
        }
        console.log(arg);
        });

        window.ipcRenderer.on('message', (event, arg) => {
        console.log(arg);
        });

        const closeModal = () => {
            const ele = document.querySelector('#electron-autoupdater');
            if (ele && ele.classList.contains('active')) {
                ele.classList.remove('active');
            }
        }

        const showError = () => {
            const ele = document.querySelector('#electron-autoupdater');
            if (ele && !ele.classList.contains('active')) {
                ele.classList.add('active');
            }
            const ele2 = document.querySelector('#electron-progress-update');
            if (ele2 && ele2.classList.contains('visible')) {
                ele2.classList.add('hidden');
                ele2.classList.remove('visible');
            }
            const ele3 = document.querySelector('#electron-install-update');
            if (ele3 && ele3.classList.contains('visible')) {
                ele3.classList.add('hidden');
                ele3.classList.remove('visible');
            }
            const ele4 = document.querySelector('#electron-download-update');
            if (ele4 && ele4.classList.contains('visible')) {
                ele4.classList.add('hidden');
                ele4.classList.remove('visible');
            }
            const ele5 = document.querySelector('#electron-error-update');
            if (ele5 && ele5.classList.contains('hidden')) {
                ele5.classList.add('visible');
                ele5.classList.remove('hidden');
            }
        }

        const showInstall = () => {
            const ele = document.querySelector('#electron-autoupdater');
            if (ele && !ele.classList.contains('active')) {
                ele.classList.add('active');
            }
            const ele2 = document.querySelector('#electron-progress-update');
            if (ele2 && ele2.classList.contains('visible')) {
                ele2.classList.add('hidden');
                ele2.classList.remove('visible');
            }
            const ele3 = document.querySelector('#electron-install-update');
            if (ele3 && ele3.classList.contains('hidden')) {
                ele3.classList.add('visible');
                ele3.classList.remove('hidden');
            }
        }

        const sendDownloadResponse = () => {
            const ele = document.querySelector('#electron-download-update');
            if (ele && ele.classList.contains('visible')) {
                ele.classList.remove('visible');
                ele.classList.add('hidden');
            }
            const ele2 = document.querySelector('#electron-progress-update');
            if (ele2 && ele2.classList.contains('hidden')) {
                ele2.classList.remove('hidden');
                ele2.classList.add('visible');
            }
            window.ipcRenderer.send('renderer-message', 'DOWNLOAD');
        };

        const sendInstallResponse = () => {
            window.ipcRenderer.send('renderer-message', 'INSTALL');
        };

        return (
            <div className="electron-autoupdater" id="electron-autoupdater">
                <div className="close-panel" onClick={closeModal} onKeyPress="">
                <span className="icon-cross11" />
                </div>

                <div className="download-update visible" id="electron-download-update">
                <h6>¡Hay una nueva versión disponible!</h6>
                <div className="footer">
                    <div className="actionButtons">
                    <button
                        className={styles.cancelButton}
                        onClick={closeModal}
                    >
                        <FormattedMessage
                            defaultMessage="Recordarme luego"
                            description="Button in prompt for cancelling the dialog"
                            id="gui.prompt.remindMeLater"
                        />
                    </button>
                    <button
                        className={styles.okButton}
                        onClick={sendDownloadResponse}
                    >
                        <FormattedMessage
                            defaultMessage="Descargar ahora"
                            description="Button in prompt for cancelling the dialog"
                            id="gui.prompt.downloadNow"
                        />
                    </button>
                    {/* <button className="install-later" onClick={closeModal}>Recordarme luego</button>
                    <button className="install-now" onClick={sendDownloadResponse}>Descargar ahora</button> */}
                    </div>
                </div>
                </div>

                <div className="progress-update hidden" id="electron-progress-update">
                <h6>Descargando...</h6>
                <div className="updater-progress">
                    <div className="updater-progress-bar">
                    <div className="updater-progress-bar-status" id="updater-progress-bar-status" style={{ width: '0%' }}></div>
                    </div>
                    <h4 id="updater-progress-bar-status-text">0%</h4>
                </div>
                <div className="updater-progress-remaining" id="updater-progress-remaining">0 / 0 Mb</div>
                </div>

                <div className="error-update hidden" id="electron-error-update">
                <h6>Error</h6>
                <div className="error-text">Ocurrió un error durante la descarga. Por favor reinicia la aplicación para volver a intentarlo.</div>
                </div>

                <div className="install-update hidden" id="electron-install-update">
                <h6>¡Actualización lista para instalar!</h6>
                <div className="footer">
                    <div className="actionButtons">
                    <button
                        className={styles.cancelButton}
                        onClick={closeModal}
                    >
                        <FormattedMessage
                            defaultMessage="Cerrar"
                            description="Button in prompt for cancelling the dialog"
                            id="gui.prompt.close"
                        />
                    </button>
                    <button
                        className={styles.okButton}
                        onClick={sendInstallResponse}
                    >
                        <FormattedMessage
                            defaultMessage="Instalar y reiniciar"
                            description="Button in prompt for cancelling the dialog"
                            id="gui.prompt.install"
                        />
                    </button>
                    {/* <TextButton className="install-later" onClick={closeModal}>Cerrar</TextButton>
                    <ButtonV2 className="install-now" onClick={sendInstallResponse}>Instalar y reiniciar</ButtonV2> */}
                    </div>
                </div>
                </div>

            </div>
        );
    }
}

ElectronAutoupdaterComponent.propTypes = {
    // isFullScreen: PropTypes.bool,
    // messageId: PropTypes.string
};
ElectronAutoupdaterComponent.defaultProps = {
    // isFullScreen: false,
    // messageId: 'gui.loader.headline'
};

export default ElectronAutoupdaterComponent;
