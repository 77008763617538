const OPEN_MENU = 'scratch-gui/menus/OPEN_MENU';
const CLOSE_MENU = 'scratch-gui/menus/CLOSE_MENU';

const MENU_REGISTRATION = 'registrationMenu';
const MENU_SAVE_PROJECT = 'saveProjectMenu';
const MENU_DETAILS_PROJECT = 'detailsProjectMenu';
const MENU_REMIX_PROJECT = 'remixProjectMenu';
const MENU_PROFILE = 'profileMenu';
const MENU_ACCOUNT = 'accountMenu';
const MENU_FILE = 'fileMenu';
const MENU_EDIT = 'editMenu';
const MENU_LANGUAGE = 'languageMenu';
const MENU_LOGIN = 'loginMenu';


const initialState = {
    [MENU_REGISTRATION]: false,
    [MENU_SAVE_PROJECT]: false,
    [MENU_DETAILS_PROJECT]: false,
    [MENU_REMIX_PROJECT]: false,
    [MENU_PROFILE]: false,
    [MENU_ACCOUNT]: false,
    [MENU_FILE]: false,
    [MENU_EDIT]: false,
    [MENU_LANGUAGE]: false,
    [MENU_LOGIN]: false
};

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
    case OPEN_MENU:
        return Object.assign({}, state, {
            [action.menu]: true
        });
    case CLOSE_MENU:
        return Object.assign({}, state, {
            [action.menu]: false
        });
    default:
        return state;
    }
};
const openMenu = menu => ({
    type: OPEN_MENU,
    menu: menu
});
const closeMenu = menu => ({
    type: CLOSE_MENU,
    menu: menu
});
const openRegistrationMenu = () => openMenu(MENU_REGISTRATION);
const closeRegistrationMenu = () => closeMenu(MENU_REGISTRATION);
const registrationOpenState = state => state.scratchGui.menus[MENU_REGISTRATION];
const saveProjectOpenState = state => state.scratchGui.menus[MENU_SAVE_PROJECT];
const openSaveProjectMenu = () => openMenu(MENU_SAVE_PROJECT);
const closeSaveProjectMenu = () => closeMenu(MENU_SAVE_PROJECT);
const detailsProjectOpenState = state => state.scratchGui.menus[MENU_DETAILS_PROJECT];
const openDetailsProjectMenu = () => openMenu(MENU_DETAILS_PROJECT);
const closeDetailsProjectMenu = () => closeMenu(MENU_DETAILS_PROJECT);
const remixProjectOpenState = state => state.scratchGui.menus[MENU_REMIX_PROJECT];
const openRemixProjectMenu = () => openMenu(MENU_REMIX_PROJECT);
const closeRemixProjectMenu = () => closeMenu(MENU_REMIX_PROJECT);
const openAccountMenu = () => openMenu(MENU_ACCOUNT);
const closeAccountMenu = () => closeMenu(MENU_ACCOUNT);
const accountMenuOpen = state => state.scratchGui.menus[MENU_ACCOUNT];
const openProfileState = state => state.scratchGui.menus[MENU_PROFILE];
const openProfileMenu = () => openMenu(MENU_PROFILE);
const closeProfileMenu = () => closeMenu(MENU_PROFILE);
const openFileMenu = () => openMenu(MENU_FILE);
const closeFileMenu = () => closeMenu(MENU_FILE);
const fileMenuOpen = state => state.scratchGui.menus[MENU_FILE];
const openEditMenu = () => openMenu(MENU_EDIT);
const closeEditMenu = () => closeMenu(MENU_EDIT);
const editMenuOpen = state => state.scratchGui.menus[MENU_EDIT];
const openLanguageMenu = () => openMenu(MENU_LANGUAGE);
const closeLanguageMenu = () => closeMenu(MENU_LANGUAGE);
const languageMenuOpen = state => state.scratchGui.menus[MENU_LANGUAGE];
const openLoginMenu = () => openMenu(MENU_LOGIN);
const closeLoginMenu = () => closeMenu(MENU_LOGIN);
const loginMenuOpen = state => state.scratchGui.menus[MENU_LOGIN];

export {
    reducer as default,
    initialState as menuInitialState,
    openAccountMenu,
    closeAccountMenu,
    accountMenuOpen,
    openProfileState,
    openProfileMenu,
    closeProfileMenu,
    openRegistrationMenu,
    closeRegistrationMenu,
    registrationOpenState,
    openFileMenu,
    closeFileMenu,
    fileMenuOpen,
    openEditMenu,
    closeEditMenu,
    editMenuOpen,
    openLanguageMenu,
    closeLanguageMenu,
    languageMenuOpen,
    openLoginMenu,
    closeLoginMenu,
    loginMenuOpen,
    saveProjectOpenState,
    openSaveProjectMenu,
    closeSaveProjectMenu,
    detailsProjectOpenState,
    openDetailsProjectMenu,
    closeDetailsProjectMenu,
    remixProjectOpenState,
    openRemixProjectMenu,
    closeRemixProjectMenu
};
