import classNames from 'classnames';
import { defineMessages, FormattedMessage, intlShape, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import React from 'react';

import Box from '../box/box.jsx';
import Modal from '../../containers/modal.jsx';

import styles from './assignmentDeliveryPrompt.css';


const messages = defineMessages({
    title: {
        defaultMessage: '¡Estás por entregar tu tarea!',
        description: 'Title for assignment delivery modal',
        id: 'gui.gui.assignmentDeliveryTitle'
    },
    messageBody: {
        defaultMessage: 'Recordá que una vez entregada no podrás modificarla.',
        description: 'A message that warns the user about assignment delivery',
        id: 'gui.gui.assignmentDeliveryMessageBody'
    }
});

const AssignmentDeliveryPrompt = props => (
    <Modal
        id={'assignmentDeliveryPrompt'}
        className={styles.modalContent}
        contentLabel={props.intl.formatMessage(messages.title)}
        onRequestClose={props.onCancel}
    >
        <Box className={styles.body}>
            <div className={styles.infoMessage}>
                <FormattedMessage
                    {...messages.messageBody}
                />
            </div>
            <Box className={styles.buttonRow}>
                <button
                    className={styles.cancelButton}
                    onClick={props.onCancel}
                >
                    <FormattedMessage
                        defaultMessage="Volver"
                        description="Button in prompt for cancelling the dialog"
                        id="gui.prompt.return"
                    />
                </button>
                <button
                    className={styles.okButton}
                    onClick={props.onOk}
                >
                    <FormattedMessage
                        defaultMessage="Entregar"
                        description="Button in prompt for confirming the dialog"
                        id="gui.prompt.delivery"
                    />
                </button>
            </Box>
        </Box>
    </Modal>
);

AssignmentDeliveryPrompt.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onOk: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
};

const AssignmentDeliveryPromptComponent = injectIntl(AssignmentDeliveryPrompt);

export default AssignmentDeliveryPromptComponent;
