import keyMirror from 'keymirror';

const SET_ASSIGNMENT_SESSION_ID = 'scratch-gui/assignment-session-state/SET_ASSIGNMENT_SESSION_ID';
const ASSIGNMENT_SESSION_ID_SET = 'scratch-gui/assignment-session-state/ASSIGNMENT_SESSION_ID_SET';

const LoadingState = keyMirror({
    SHOWING_ASSIGNMENT_SESSION_WITH_ID: null,
});
const LoadingStates = Object.keys(LoadingState);

const initialState = {
    assignmentSessionId: null,
    loadingState: null,
};

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;

    switch (action.type) {
        case SET_ASSIGNMENT_SESSION_ID:
            return Object.assign({}, state, {
                assignmentSessionId: action.assignmentSessionId || null,
            });
    default:
        return state;
    }
};

const setAssignmentSessionId = (id) => {
    return {
        type: SET_ASSIGNMENT_SESSION_ID,
        assignmentSessionId: id,
    };
};

export {
    reducer as default,
    initialState as assignmentSessionStateInitialState,
    LoadingState,
    LoadingStates,
    setAssignmentSessionId,
}