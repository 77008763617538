import * as routes from '@educabot/educablocks-cosmos';

export const getCosmosItem = (key) => {
  const domain = (process.env.THEME_DOMAIN) ? process.env.THEME_DOMAIN : null;
  const environment = (process.env.THEME_ENV) ? process.env.THEME_ENV : ((process.env.NODE_ENV) ? process.env.NODE_ENV: null);
  const cosmosItem = routes.getCosmosItem(domain, environment, key);
//   console.log('===============env', domain, environment, cosmosItem, key);
  return cosmosItem || null;
};

export const getCosmosImageItem = (key, fallback, internalFolderStructure = '') => {
  const imageRoute = getCosmosItem(key);
  return `${internalFolderStructure}${imageRoute || fallback}`;
};

export const getLogo = () => getCosmosImageItem('logo', 'logo.svg');
export const getFavicon = () => getCosmosImageItem('favicon', 'default.svg');
