import axios from 'axios';
import * as routes from '@educabot/educablocks-cosmos';

axios.defaults.withCredentials = true;

const Types = {
    SET_SESSION_FETCH: 'SET_SESSION_FETCH',
    SET_SESSION_ERROR: 'SET_SESSION_ERROR',
    SET_SESSION_FETCHING: 'SET_SESSION_FETCHING',
    SET_LOGIN_FETCHING: 'SET_LOGIN_FETCHING',
    SET_LOGIN_ERROR: 'SET_LOGIN_ERROR',
    SET_REGISTRATION_FETCHING: 'SET_REGISTRATION_FETCHING',
    SET_REGISTRATION_FETCH: 'SET_REGISTRATION_FETCH',
    SET_REGISTRATION_ERROR: 'SET_REGISTRATION_ERROR'
};

const sessionReducer = (state, action) => {
    if (typeof state === 'undefined') {
        state = getInitialState();
    }
    switch (action.type) {
        case Types.SET_SESSION_FETCHING:
        case Types.SET_SESSION_FETCH:
        case Types.SET_SESSION_ERROR:
        case Types.SET_LOGIN_FETCHING:
        case Types.SET_LOGIN_ERROR:
        case Types.SET_REGISTRATION_FETCHING:
        case Types.SET_REGISTRATION_FETCH:
        case Types.SET_REGISTRATION_ERROR:
            return Object.assign({}, state, action.payload);
        default:
            return state;
    }
};

const setRegistrationFetching = () => ({
    type: Types.SET_REGISTRATION_FETCHING,
    payload: { status: 'registration_fetching', data: {} }
});

const setRegistrationSuccess = () => ({
    type: Types.SET_REGISTRATION_FETCH,
    payload: { status: 'registration_fetch', data: {} }
});

const setRegistrationError = (error) => ({
    type: Types.SET_REGISTRATION_ERROR,
    payload: { status: 'registration_error', data: { error: error.response.data } }
});

const setLoginFetching = () => ({
    type: Types.SET_LOGIN_FETCHING,
    payload: { status: 'login_fetching', data: {} }
});

const setLoginError = error => ({
    type: Types.SET_LOGIN_ERROR,
    payload: { status: 'login_error', data: { error: error } }
});

const getInitialState = () => ({
    status: 'session_fetching',
    data: {}
});

const setSessionError = error => ({
    type: Types.SET_SESSION_ERROR,
    payload: { status: 'session_error', data: { error: error } }
});

const setSession = session => ({
    type: Types.SET_SESSION_FETCH,
    payload: { status: 'session_fetch', data: session }
});

const checkAuthAction = () => (dispatch => {
    // console.log('CHECKAUTH');
    return new Promise((resolve, reject) => {
        axios.get(routes.checkUserLoggedUri).then((response) => {
            console.log()
            dispatch(setSession(response.data));
        }).catch((err) => {
            dispatch(setSessionError(err));
        });
    });
});


const handleRegistration = (formData, callback) => (dispatch => {
    // console.log('SIGNUP', formData);
    dispatch(setRegistrationFetching());
    return new Promise((resolve, reject) => {
        axios.post(routes.signUpUri, formData).then((response) => {
            // console.log('success', response.data);
            dispatch(setRegistrationSuccess());
            if (callback) callback();
        }).catch((error) => {
            dispatch(setRegistrationError(error));
            if (callback) callback(error.response.data);
        });
    });
});


const handleLogin = (formData, callback) => (dispatch => {
    // console.log('LOGIN', formData);
    dispatch(setLoginFetching());
    return new Promise((resolve, reject) => {
        axios.post(routes.loginUri, formData).then((response) => {
            // console.log(response.data);
            dispatch(checkAuthAction());
            if (callback) callback();
        }).catch((err) => {
            dispatch(setLoginError('general.loginError'));
            if (callback) callback();
        });
    });
});

const handleLogout = () => (dispatch => {
    // console.log('LOGINOUT');
    dispatch(setLoginFetching());
    return new Promise((resolve, reject) => {
        axios.get(routes.logoutUri).then((response) => {
            dispatch(checkAuthAction());
        }).catch((err) => {
            dispatch(setLoginError('general.loginError'));
        });
    });
});


export {
    // getSession,
    checkAuthAction, sessionReducer as default, handleLogin,
    handleLogout, handleRegistration
};

