import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { defineMessages, injectIntl, intlShape } from 'react-intl';

import GreenFlag from '../green-flag/green-flag.jsx';
import StopAll from '../stop-all/stop-all.jsx';
import TurboMode from '../turbo-mode/turbo-mode.jsx';

import styles from './controls.css';
import Button from '../button/button.jsx';
import AssignmentDeliveryPrompt from '../../containers/assignmentDeliveryPrompt.jsx';

const messages = defineMessages({
    goTitle: {
        id: 'gui.controls.go',
        defaultMessage: 'Go',
        description: 'Green flag button title'
    },
    stopTitle: {
        id: 'gui.controls.stop',
        defaultMessage: 'Stop',
        description: 'Stop button title'
    },
    assignmentDeliveryTitle: {
        id: 'gui.controls.assignmentDelivery',
        defaultMessage: 'Entregar',
        description: 'Delivery button title'
    }
});

const Controls = function (props) {
    const {
        active,
        className,
        intl,
        onGreenFlagClick,
        onStopAllClick,
        turbo,
        onAssignmentDeliveryClick,
        onAssignmentDeliveryPromptOpen,
        onAssignmentDeliveryPromptClose,
        isAssignmentProjectEducabot,
        isAssignmentProjectTeacher,
        isAssignmentProjectDeliverable,
        assignmentPromptStateOpen,
        ...componentProps
    } = props;
    return (
        <div
            className={classNames(styles.controlsContainer, className)}
            {...componentProps}
        >
            <GreenFlag
                active={active}
                title={intl.formatMessage(messages.goTitle)}
                onClick={onGreenFlagClick}
            />
            <StopAll
                active={active}
                title={intl.formatMessage(messages.stopTitle)}
                onClick={onStopAllClick}
            />
            {(isAssignmentProjectEducabot && !isAssignmentProjectTeacher && isAssignmentProjectDeliverable) ? (
                <React.Fragment>
                    <Button
                        className={classNames(
                            styles.assignmentDeliveryButton,
                            className, {
                            [styles.active]: active
                        }
                        )}
                        onClick={onAssignmentDeliveryPromptOpen}
                        {...props}
                    >
                        {intl.formatMessage(messages.assignmentDeliveryTitle)}
                    </Button>
                    {(assignmentPromptStateOpen) ? (
                        <AssignmentDeliveryPrompt
                            onOk={onAssignmentDeliveryClick}
                            onCancel={onAssignmentDeliveryPromptClose}
                        />
                    ) : null}
                </React.Fragment>
            ) : null}
            {turbo ? (
                <TurboMode />
            ) : null}
        </div>
    );
};

Controls.propTypes = {
    active: PropTypes.bool,
    className: PropTypes.string,
    intl: intlShape.isRequired,
    onGreenFlagClick: PropTypes.func.isRequired,
    onStopAllClick: PropTypes.func.isRequired,
    turbo: PropTypes.bool,
    onAssignmentDeliveryClick: PropTypes.func.isRequired,
    onAssignmentDeliveryPromptOpen: PropTypes.func.isRequired,
    onAssignmentDeliveryPromptClose: PropTypes.func.isRequired,
    isAssignmentProjectEducabot: PropTypes.bool,
    isAssignmentProjectTeacher: PropTypes.bool,
    isAssignmentProjectDeliverable: PropTypes.bool,
    assignmentPromptStateOpen: PropTypes.bool,
};

Controls.defaultProps = {
    active: false,
    turbo: false,
    assignmentPromptStateOpen: false,
};

export default injectIntl(Controls);
