import * as routes from '@educabot/educablocks-cosmos';
import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {defineMessages, injectIntl, intlShape} from 'react-intl';
import VM from 'scratch-vm';
import {setProjectTitle} from '../reducers/project-title';
import {doneLoadingProject, requestNewProject, startLoadingProject} from '../reducers/project-state';
import {showAlertWithTimeout} from '../reducers/alerts';
import {
    openLoadingProject,
    closeLoadingProject,
    openProjectsLibrary,
    closeProjectsLibrary
} from '../reducers/modals';

import LibraryComponent from '../components/project/library.jsx';
import { deleteProjectDB } from '../lib/educabot-api';

const messages = defineMessages({
    libraryTitle: {
        defaultMessage: 'Choose a Project',
        description: 'Heading for the projects library',
        id: 'project.chooseAProject'
    },
    smartTeam: {
        defaultMessage: 'SmartTeam',
        description: 'Tag for filtering SmartTeam projects',
        id: 'project.smartTeam'
    },
    public: {
        defaultMessage: 'Public',
        description: 'Tag for filtering public projects',
        id: 'project.public'
    },
    private: {
        defaultMessage: 'Private',
        description: 'Tag for filtering private projects',
        id: 'project.private'
    },
    loadError: {
        defaultMessage: 'Can\'t load project\'s file',
        description: 'Message for loading project error',
        id: 'project.loadError'
    },
    confirmDeleteProject: {
        defaultMessage: 'Are you sure to delete this project?',
        description: 'Message for removing a project',
        id: 'project.confirmDeleteProject'
    },
});

const projectTags = [
    {tag: 'private', intlLabel: messages.private},
    {tag: 'public', intlLabel: messages.public},
    {tag: 'smartTeam', intlLabel: messages.smartTeam}
];

class ProjectsLibrary extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'handleItemSelect',
            'handleDeleteProject'
        ]);
    }
    handleItemSelect (item) {
        this.props.onStartLoadingProject(item.data.id, item.data);
        // console.log('item selected', item.data.jsonBody);
        // this.props.onOpenLoadingProject();

        // fetch(
        //     `${routes.userProjectsUri}/animations/proxy/${encodeURIComponent(item.data.jsonBody.projectZipFile)}`,
        //     {
        //         method: 'GET',
        //         headers: {
        //             "Content-type": "application/octet-stream"
        //         },
        //     },
        // ).then(result => {
        //     // console.log('SB3 FILE', result);
        //     return result.arrayBuffer();
        // }).then(response => {
        //     // console.log('SB3 FILE ARRAYBUFFER', response);
        //     this.props.vm.loadProject(response)
        //         .then(() => {
        //             this.props.onCloseLoadingProject();
        //             this.props.onReceivedProjectTitle(item.data.title);
        //             // this.props.onDoneLoadingProject(item.data.id.toString(), item.data.userId.toString());
        //             this.props.onDoneLoadingProject(item.data.id.toString(), item.data, item.data.userId.toString());
        //         })
        //         .catch(error => {
        //             this.props.onCloseLoadingProject();
        //             console.log('ERROR LOADING PROJECT', error);
        //             alert(this.props.intl.formatMessage(messages.loadError)); // eslint-disable-line no-alert
        //         });
        // }).catch((error) => {
        //     this.props.onCloseLoadingProject();
        //     console.log('ERROR LOADING PROJECT', error);
        //     alert(this.props.intl.formatMessage(messages.loadError));
        //     this.props.onShowLoadingErrorAlert();
        // });
    }

    handleDeleteProject (id) {
        const readyToDeleteProject = confirm(this.props.intl.formatMessage(messages.confirmDeleteProject));
        if (readyToDeleteProject) {
            this.props.onDeleteProjectDB(id).then(result => {
                if (this.props.projectId === id.toString()) {
                    this.props.onClickNew();
                }
                this.props.onCloseProjectLibrary();
                this.props.onOpenProjectLibrary();
            }).catch(err => {
                alert(err);
            });
        }
    }

    render () {
        return (
            <LibraryComponent
                // data={backdropLibraryContent}
                id="ProjectsLibrary"
                tags={projectTags}
                title={this.props.intl.formatMessage(messages.libraryTitle)}
                onItemSelected={this.handleItemSelect}
                onRequestClose={this.props.onRequestClose}
                onDeleteProject={this.handleDeleteProject}
                userData={this.props.userData}
            />
        );
    }
}

ProjectsLibrary.propTypes = {
    intl: intlShape.isRequired,
    onRequestClose: PropTypes.func,
    vm: PropTypes.instanceOf(VM).isRequired,
    onReceivedProjectTitle: PropTypes.func,
    onDoneLoadingProject: PropTypes.func,
    onShowLoadingErrorAlert: PropTypes.func,
    onOpenLoadingProject: PropTypes.func,
    onCloseLoadingProject: PropTypes.func,
    onDeleteProjectDB: PropTypes.func,
    onOpenProjectLibrary: PropTypes.func,
    onCloseProjectLibrary: PropTypes.func,
    userData: PropTypes.object,
    onClickNew: PropTypes.func,
    projectId: PropTypes.string,
    onStartLoadingProject: PropTypes.func,
};

// export default injectIntl(ProjectsLibrary);

const IntlProjectsLibrary = injectIntl(ProjectsLibrary);

const mapStateToProps = state => {
    const loadingState = state.scratchGui.projectState.loadingState;
    const userData = state.scratchGui.sessionReducer.data.data;
    // console.log('LOADINGSTATE', loadingState);
    return {
        loadingState: loadingState,
        userData: userData,
        projectId: state.scratchGui.projectState.projectId.toString(),
        onDeleteProjectDB: (id) => deleteProjectDB(id),
    };
};

const mapDispatchToProps = dispatch => ({
    onOpenLoadingProject: () => dispatch(openLoadingProject()),
    onCloseLoadingProject: () => dispatch(closeLoadingProject()),
    onReceivedProjectTitle: title => dispatch(setProjectTitle(title)),
    // onDoneLoadingProject: (id, userId) => dispatch(doneLoadingProject(id, userId)),
    onDoneLoadingProject: (id, details, userId) => dispatch(doneLoadingProject(id, details, userId)),
    onShowLoadingErrorAlert: () => showAlertWithTimeout(dispatch, 'loadingError'),
    onOpenProjectLibrary: () => dispatch(openProjectsLibrary()),
    onCloseProjectLibrary: () => dispatch(closeProjectsLibrary()),
    onClickNew: () => dispatch(requestNewProject(true)),
    onStartLoadingProject: (projectId, projectDetails) => dispatch(startLoadingProject(projectId, projectDetails)),
});

const ConnectedProjectsLibrary = connect(
    mapStateToProps,
    mapDispatchToProps,
)(IntlProjectsLibrary);

export default ConnectedProjectsLibrary;
