const PropTypes = require('prop-types');
const React = require('react');
const connect = require('react-redux').connect;

import { closeRegistrationMenu } from '../../reducers/menus';

import JoinModal from '../modal/join/modal.jsx';
// const JoinModal = require('../modal/join/modal.jsx');

require('./registration.scss');

const Registration = ({
    createProjectOnComplete,
    handleCloseRegistration,
    handleCompleteRegistration,
    isOpen,
    showCloseButton,
}) => (
    <div>
        <JoinModal
            createProjectOnComplete={createProjectOnComplete}
            isOpen={isOpen}
            key="join-modal"
            showCloseButton={showCloseButton}
            onCompleteRegistration={handleCompleteRegistration}
            onRequestClose={handleCloseRegistration}
        />
    </div>
);

Registration.propTypes = {
    createProjectOnComplete: PropTypes.bool,
    handleCloseRegistration: PropTypes.func,
    handleCompleteRegistration: PropTypes.func,
    isOpen: PropTypes.bool,
    showCloseButton: PropTypes.bool,
};

const mapDispatchToProps = (dispatch, ownProps) => ({
    handleCloseRegistration: () => {
        dispatch(closeRegistrationMenu());
    },
    handleCompleteRegistration: () => {
        dispatch(closeRegistrationMenu());
    }
});

export default connect(
    () => ({}),
    mapDispatchToProps
)(Registration);
