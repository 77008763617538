import analytics from '../lib/analytics';

const CLOSE_CARDS = 'scratch-gui/project-details-cards/CLOSE_CARDS';
const SHRINK_EXPAND_CARDS = 'scratch-gui/project-details-cards/SHRINK_EXPAND_CARDS';
const VIEW_CARDS = 'scratch-gui/project-details-cards/VIEW_CARDS';
const ACTIVATE_DECK = 'scratch-gui/project-details-cards/ACTIVATE_DECK';
const NEXT_STEP = 'scratch-gui/project-details-cards/NEXT_STEP';
const PREV_STEP = 'scratch-gui/project-details-cards/PREV_STEP';
const DRAG_CARD = 'scratch-gui/project-details-cards/DRAG_CARD';
const START_DRAG = 'scratch-gui/project-details-cards/START_DRAG';
const END_DRAG = 'scratch-gui/project-details-cards/END_DRAG';

const initialState = {
    visible: false,
    content: {},
    step: 0,
    x: 0,
    y: 0,
    expanded: true,
    dragging: false
};

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
    case CLOSE_CARDS:
        return Object.assign({}, state, {
            visible: false
        });
    case SHRINK_EXPAND_CARDS:
        return Object.assign({}, state, {
            expanded: !state.expanded
        });
    case VIEW_CARDS:
        return Object.assign({}, state, {
            visible: true
        });
    case ACTIVATE_DECK:
        let image = null;
        let youTubeVideo = null;
        if (action.projectState.imageUrl) {
            const imgExt = action.projectState.imageUrl.split('.').at(-1);
            if (imgExt === 'gif' || imgExt === 'png' || imgExt === 'jpg' || imgExt === 'jpeg') {
                image = action.projectState.imageUrl;
            } else {
                youTubeVideo = action.projectState.imageUrl;
            }
        }
        const content = {
            name: action.projectState.title,
            steps: [
                {
                    title: action.projectState.title,
                    description: action.projectState.description,
                },
            ],
        };
        if (image || youTubeVideo) {
            content.steps.push({
                image,
                youTubeVideo,
            });
        }

        return Object.assign({}, state, {
            content,
            step: 0,
            x: 0,
            y: 0,
            expanded: true,
            visible: true
        });
    case NEXT_STEP:
        analytics.event({
            category: 'how-to',
            action: 'next step',
            label: `${state.step}`
        });
        return Object.assign({}, state, {
            step: state.step + 1
        });
        return state;
    case PREV_STEP:
        if (state.step > 0) {
            return Object.assign({}, state, {
                step: state.step - 1
            });
        }
        return state;
    case DRAG_CARD:
        return Object.assign({}, state, {
            x: action.x,
            y: action.y
        });
    case START_DRAG:
        return Object.assign({}, state, {
            dragging: true
        });
    case END_DRAG:
        return Object.assign({}, state, {
            dragging: false
        });
    default:
        return state;
    }
};

const activateDeck = function (projectState) {
    return {
        type: ACTIVATE_DECK,
        projectState,
    };
};

const viewCards = function () {
    return {type: VIEW_CARDS};
};

const closeCards = function () {
    return {type: CLOSE_CARDS};
};

const shrinkExpandCards = function () {
    return {type: SHRINK_EXPAND_CARDS};
};

const nextStep = function () {
    return {type: NEXT_STEP};
};

const prevStep = function () {
    return {type: PREV_STEP};
};

const dragCard = function (x, y) {
    return {type: DRAG_CARD, x, y};
};

const startDrag = function () {
    return {type: START_DRAG};
};

const endDrag = function () {
    return {type: END_DRAG};
};

export {
    reducer as default,
    initialState as projectDetailsCardsInitialState,
    activateDeck,
    viewCards,
    closeCards,
    shrinkExpandCards,
    nextStep,
    prevStep,
    dragCard,
    startDrag,
    endDrag
};
