import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import VM from 'scratch-vm';
import {connect} from 'react-redux';

import ControlsComponent from '../components/controls/controls.jsx';
import { getIsAssignmentTeacherEducabot, getIsAssignmentProjectDeliverable, updateAssignment } from '../reducers/project-state';
import { updateAssignmentProjectDB, doSaveProjectSb3 } from '../lib/educabot-api';
import {showAlertWithTimeout} from '../reducers/alerts';
class Controls extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'handleGreenFlagClick',
            'handleStopAllClick',
            'handleAssignmentDeliveryClick',
            'handleAssignmentDeliveryPromptOpen',
            'handleAssignmentDeliveryPromptClose',
        ]);
        this.state = {
            assignmentPrompt: false
        };
    }
    handleGreenFlagClick (e) {
        e.preventDefault();
        if (e.shiftKey) {
            this.props.vm.setTurboMode(!this.props.turbo);
        } else {
            if (!this.props.isStarted) {
                this.props.vm.start();
            }
            this.props.vm.greenFlag();
        }
    }
    handleStopAllClick (e) {
        e.preventDefault();
        this.props.vm.stopAll();
    }
    handleAssignmentDeliveryClick () {
        let projectData = {};
        let settings = {};
        let assignmentData = this.props.assignmentData;
        if (this.props.projectDetails && assignmentData) {
            for (let i = 0; i < assignmentData.AssignmentSessionsProjects.length; i += 1) {
                if (assignmentData.AssignmentSessionsProjects[i].Project.id === parseInt(this.props.projectDetails.id)) {
                    settings = assignmentData.AssignmentSessionsProjects[i].settings || {}
                    projectData = assignmentData.AssignmentSessionsProjects[i].Project || {}
                }
            }
        }
        settings.delivered = new Date();
        this.setState({ assignmentPrompt: false });
        // this.props.deliverAssignment(this.props.assignmentId, this.props.projectDetails.id, { settings }).then(result => {
        this.props.doSaveProjectSb3(this.props.vm, projectData, this.props.userData.userId, false).then(resultSave => {
            this.props.onShowDeliveringAlert();
            this.props.deliverAssignment(this.props.assignmentId, this.props.projectDetails.id, { settings }).then(result => {
                console.log('[DELIVER RESULT]',  result);
                if (this.props.projectDetails && assignmentData) {
                    for (let i = 0; i < assignmentData.AssignmentSessionsProjects.length; i += 1) {
                        if (assignmentData.AssignmentSessionsProjects[i].Project.id === parseInt(this.props.projectDetails.id)) {
                            assignmentData.AssignmentSessionsProjects[i].settings = result.data.settings;
                        }
                    }
                    this.props.onShowLoadingSuccessAlert();
                    this.props.onUpdateAssignment(assignmentData);
                }
            }).catch(err =>  {
                // alert(err.response.data.message);
                this.props.onShowLoadingErrorAlert();
            });
        }).catch(err =>  {
            // alert(err.response.data.message);
            this.props.onShowLoadingErrorAlert();
        });
    }
    handleAssignmentDeliveryPromptOpen () {
        this.setState({ assignmentPrompt: true });
    }
    handleAssignmentDeliveryPromptClose () {
        this.setState({ assignmentPrompt: false });
    }
    render () {
        const {
            vm, // eslint-disable-line no-unused-vars
            isStarted, // eslint-disable-line no-unused-vars
            projectRunning,
            turbo,
            isAssignmentProjectEducabot,
            isAssignmentProjectTeacher,
            isAssignmentProjectDeliverable,
            deliverAssignment,
            projectDetails,
            assignmentId,
            assignmentData,
            onUpdateAssignment,
            ...props
        } = this.props;
        return (
            <ControlsComponent
                {...props}
                active={projectRunning}
                turbo={turbo}
                onGreenFlagClick={this.handleGreenFlagClick}
                onStopAllClick={this.handleStopAllClick}
                onAssignmentDeliveryClick={this.handleAssignmentDeliveryClick}
                isAssignmentProjectEducabot={isAssignmentProjectEducabot}
                isAssignmentProjectTeacher={isAssignmentProjectTeacher}
                isAssignmentProjectDeliverable={isAssignmentProjectDeliverable}
                onAssignmentDeliveryPromptOpen={this.handleAssignmentDeliveryPromptOpen}
                onAssignmentDeliveryPromptClose={this.handleAssignmentDeliveryPromptClose}
                assignmentPromptStateOpen={this.state.assignmentPrompt}
            />
        );
    }
}

Controls.propTypes = {
    isStarted: PropTypes.bool.isRequired,
    projectRunning: PropTypes.bool.isRequired,
    turbo: PropTypes.bool.isRequired,
    vm: PropTypes.instanceOf(VM),
    isAssignmentProjectEducabot: PropTypes.bool,
    isAssignmentProjectTeacher: PropTypes.bool,
    isAssignmentProjectDeliverable: PropTypes.bool,
    projectDetails: PropTypes.object,
    assignmentData: PropTypes.object,
    assignmentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    deliverAssignment: PropTypes.func,
    doSaveProjectSb3: PropTypes.func,
    onUpdateAssignment: PropTypes.func,
    onShowLoadingErrorAlert: PropTypes.func,
    onShowLoadingSuccessAlert: PropTypes.func,
    onShowDeliveringAlert: PropTypes.func,
    userData: PropTypes.object,
};

const mapStateToProps = state => ({
    isStarted: state.scratchGui.vmStatus.running,
    projectRunning: state.scratchGui.vmStatus.running,
    turbo: state.scratchGui.vmStatus.turbo,
    assignmentId: state.scratchGui.projectState.assignmentId || null,
    assignmentData: state.scratchGui.projectState.assignmentData || null,
    projectDetails: state.scratchGui.projectState.projectDetails || null,
    isAssignmentProjectEducabot: (state.scratchGui.projectState.assignmentId) ? true : false,
    isAssignmentProjectTeacher: getIsAssignmentTeacherEducabot(state.scratchGui.projectState),
    isAssignmentProjectDeliverable: getIsAssignmentProjectDeliverable(state.scratchGui),
    deliverAssignment: updateAssignmentProjectDB,
    userData: state.scratchGui.sessionReducer.data.data,
    vm: state.scratchGui.vm,
    doSaveProjectSb3: doSaveProjectSb3
});

const mapDispatchToProps = dispatch => ({
    onUpdateAssignment: (assignmentData) => dispatch(updateAssignment(assignmentData)),
    onShowLoadingErrorAlert: () => showAlertWithTimeout(dispatch, 'deliveryAssignmentError'),
    onShowLoadingSuccessAlert: () => showAlertWithTimeout(dispatch, 'deliveryAssignmentSuccess'),
    onShowDeliveringAlert: () => showAlertWithTimeout(dispatch, 'deliveringAssignment')
});

export default connect(mapStateToProps, mapDispatchToProps)(Controls);
