import PropTypes from 'prop-types';
import React from 'react';
import bindAll from 'lodash.bindall';
import AssignmentDeliveryPromptComponent from '../components/assignmentDeliveryPrompt/assignmentDeliveryPrompt.jsx';

class AssignmentDeliveryPrompt extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'handleOk',
            'handleCancel',
        ]);
    }
    handleOk () {
        this.props.onOk();
    }
    handleCancel () {
        this.props.onCancel();
    }
    render () {
        return (
            <AssignmentDeliveryPromptComponent
                onCancel={this.handleCancel}
                onOk={this.handleOk}
            />
        );
    }
}

AssignmentDeliveryPrompt.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onOk: PropTypes.func.isRequired,
};

export default AssignmentDeliveryPrompt;
