import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl, intlShape, defineMessages } from 'react-intl';
import VM from 'scratch-vm';

import spriteLibraryContent from '../lib/libraries/sprites.json';
import randomizeSpritePosition from '../lib/randomize-sprite-position';
import spriteTags from '../lib/libraries/sprite-tags';

import LibraryComponent from '../components/library/library.jsx';

const messages = defineMessages({
    libraryTitle: {
        defaultMessage: 'Choose a Sprite',
        description: 'Heading for the sprite library',
        id: 'gui.spriteLibrary.chooseASprite'
    }
});


// UTILITY TO IMPORT COSTUMES FROM SPRITES
// const spritesSmartTeam = spriteLibraryContent.filter((s) => s.tags.includes('smartteam'));
// const spritesEducabot = spriteLibraryContent.filter((s) => s.tags.includes('educabot'));
// const jsonCostume = [];
// spritesEducabot.map((s) => {
//     s.json.costumes.map((c) => {
//         jsonCostume.push({
//             "name": (!c.name.includes(s.json.name)) ? s.json.name + ' ' + c.name : c.name,
//             "md5": c.md5ext,
//             "type": "costume",
//             "tags": s.tags,
//             "info": [
//                 c.rotationCenterX,
//                 c.rotationCenterY,
//                 c.bitmapResolution
//             ]
//         });
//     });
// });
// console.log('====================SPRITES', jsonCostume);

class SpriteLibrary extends React.PureComponent {
    constructor(props) {
        super(props);
        bindAll(this, [
            'handleItemSelect'
        ]);
    }
    handleItemSelect(item) {
        // Randomize position of library sprite
        randomizeSpritePosition(item);
        this.props.vm.addSprite(JSON.stringify(item.json)).then(() => {
            this.props.onActivateBlocksTab();
        });
    }
    render() {
        return (
            <LibraryComponent
                data={spriteLibraryContent}
                id="spriteLibrary"
                tags={spriteTags}
                title={this.props.intl.formatMessage(messages.libraryTitle)}
                onItemSelected={this.handleItemSelect}
                onRequestClose={this.props.onRequestClose}
            />
        );
    }
}

SpriteLibrary.propTypes = {
    intl: intlShape.isRequired,
    onActivateBlocksTab: PropTypes.func.isRequired,
    onRequestClose: PropTypes.func,
    vm: PropTypes.instanceOf(VM).isRequired
};

export default injectIntl(SpriteLibrary);
