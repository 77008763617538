import React from 'react';
import {FormattedMessage} from 'react-intl';

// Tutorial thumbnails: Avoid using any text that would need to be
// translated in thumbnails.

// Educabot - Familia BOT
import primerosPasosFolder from './thumbnails/1.0.jpg';
import moverPersonajeFolder from './thumbnails/2.0.jpg';
import agregarFondoFolder from './thumbnails/3.0.jpg';
import agregarPersonajeFolder from './thumbnails/4.0.jpg';
import utilizarFlechasFolder from './thumbnails/5.0.jpg';

export default {

    'primeros-pasos': {
        name: (
            <FormattedMessage
                defaultMessage="Primeros pasos"
                description="Educabot tutorial"
                id="gui.howtos.primeros-pasos.name"
            />
        ),
        tags: ['educabot'],
        img: primerosPasosFolder,
        steps: [{
            youTubeVideo: 'RZpCL3Fmo7s'
        }, {
            title: (
                <FormattedMessage
                    defaultMessage="Agrega el evento play verde para comenzar"
                    description="Educabot tutorial 1.2"
                    id="gui.howtos.primeros-pasos-1-2.name"
                />
            ),
            image: 'educabot12'
        }, {
            title: (
                <FormattedMessage
                    defaultMessage="Coloca el bloque decir durante 2 segundos"
                    description="Educabot tutorial 1.3"
                    id="gui.howtos.primeros-pasos-1-3.name"
                />
            ),
            image: 'educabot13'
        }, {
            title: (
                <FormattedMessage
                    defaultMessage="Cambia de disfraz"
                    description="Educabot tutorial 1.4"
                    id="gui.howtos.primeros-pasos-1-4.name"
                />
            ),
            image: 'educabot14'
        }, {
            title: (
                <FormattedMessage
                    defaultMessage="Presiona el play verde"
                    description="Educabot tutorial 1.5"
                    id="gui.howtos.primeros-pasos-1-5.name"
                />
            ),
            image: 'educabot15'
        }, {
            deckIds: [
                'mover-personaje',
                'agregar-fondo'
            ]
        }
        ],
        urlId: 'primerosPasos'
    },
    

    'mover-personaje': {
        name: (
            <FormattedMessage
                defaultMessage="Mover un personaje"
                description="Educabot tutorial"
                id="gui.howtos.mover-personaje.name"
            />
        ),
        tags: ['educabot'],
        img: moverPersonajeFolder,
        steps: [
            {
                title: (
                    <FormattedMessage
                        defaultMessage="Vamos a agregar un fondo a nuestro proyecto"
                        description="Educabot tutorial 2.1"
                        id="gui.howtos.mover-personaje-2-1.name"
                    />
                ),
                image: 'educabot21'
            }, {
                title: (
                    <FormattedMessage
                        defaultMessage="Agrega el mover 10 pasos"
                        description="Educabot tutorial 2.2"
                        id="gui.howtos.mover-personaje-2-2.name"
                    />
                ),
                image: 'educabot22'
            }, {
                title: (
                    <FormattedMessage
                        defaultMessage="Coloca el bloque al hacer clic sobre este objeto"
                        description="Educabot tutorial 2.3"
                        id="gui.howtos.mover-personaje-2-3.name"
                    />
                ),
                image: 'educabot23'
            }, {
                title: (
                    <FormattedMessage
                        defaultMessage="Presiona el objeto para que se mueva"
                        description="Educabot tutorial 2.4"
                        id="gui.howtos.mover-personaje-2-4.name"
                    />
                ),
                image: 'educabot24'
            }, {
                deckIds: [
                    'utilizar-flechas',
                    'agregar-personaje'
                ]
            }
        ],
        urlId: 'moverPersonaje'
    },
    

    'agregar-fondo': {
        name: (
            <FormattedMessage
                defaultMessage="Agregar un fondo"
                description="Educabot tutorial"
                id="gui.howtos.agregar-fondo.name"
            />
        ),
        tags: ['educabot'],
        img: agregarFondoFolder,
        steps: [
            {
                title: (
                    <FormattedMessage
                        defaultMessage="Vamos a agregar un fondo a nuestro proyecto"
                        description="Educabot tutorial 3.1"
                        id="gui.howtos.agregar-fondo-3-1.name"
                    />
                ),
                image: 'educabot31'
            }, {
                title: (
                    <FormattedMessage
                        defaultMessage="Selecciona elegir un fondo"
                        description="Educabot tutorial 3.2"
                        id="gui.howtos.agregar-fondo-3-2.name"
                    />
                ),
                image: 'educabot32'
            }, {
                title: (
                    <FormattedMessage
                        defaultMessage="Selecciona un fondo de la biblioteca"
                        description="Educabot tutorial 3.3"
                        id="gui.howtos.agregar-fondo-3-3.name"
                    />
                ),
                image: 'educabot33'
            }, {
                deckIds: [
                    'agregar-personaje',
                    'mover-personaje'
                ]
            }
        ],
        urlId: 'agregarFondo'
    },
    

    'agregar-personaje': {
        name: (
            <FormattedMessage
                defaultMessage="Agregar un personaje"
                description="Educabot tutorial"
                id="gui.howtos.agregar-personaje.name"
            />
        ),
        tags: ['educabot'],
        img: agregarPersonajeFolder,
        steps: [
            {
                title: (
                    <FormattedMessage
                        defaultMessage="Vamos a agregar un personaje a nuestro proyecto"
                        description="Educabot tutorial 4.1"
                        id="gui.howtos.agregar-personaje-4-1.name"
                    />
                ),
                image: 'educabot41'
            }, {
                title: (
                    <FormattedMessage
                        defaultMessage="Selecciona elegir un personaje"
                        description="Educabot tutorial 4.2"
                        id="gui.howtos.agregar-personaje-4-2.name"
                    />
                ),
                image: 'educabot42'
            }, {
                title: (
                    <FormattedMessage
                        defaultMessage="Selecciona un personaje de la biblioteca"
                        description="Educabot tutorial 4.3"
                        id="gui.howtos.agregar-personaje-4-3.name"
                    />
                ),
                image: 'educabot43'
            }, {
                title: (
                    <FormattedMessage
                        defaultMessage="Ubicalo donde más te guste"
                        description="Educabot tutorial 4.4"
                        id="gui.howtos.agregar-personaje-4-4.name"
                    />
                ),
                image: 'educabot44'
            }, {
                title: (
                    <FormattedMessage
                        defaultMessage="Podés eliminar el personaje por defecto"
                        description="Educabot tutorial 4.5"
                        id="gui.howtos.agregar-personaje-4-5.name"
                    />
                ),
                image: 'educabot45'
            }, {
                title: (
                    <FormattedMessage
                        defaultMessage="Agrega el bloque siguiente disfraz"
                        description="Educabot tutorial 4.6"
                        id="gui.howtos.agregar-personaje-4-6.name"
                    />
                ),
                image: 'educabot46'
            }, {
                title: (
                    <FormattedMessage
                        defaultMessage="Coloca el bloque al hacer clic sobre este objeto"
                        description="Educabot tutorial 4.7"
                        id="gui.howtos.agregar-personaje-4-7.name"
                    />
                ),
                image: 'educabot47'
            }, {
                title: (
                    <FormattedMessage
                        defaultMessage="Presiona el objeto para que cambie de disfraz"
                        description="Educabot tutorial 4.8"
                        id="gui.howtos.agregar-personaje-4-8.name"
                    />
                ),
                image: 'educabot48'
            }, {
                deckIds: [
                    'agregar-fondo',
                    'mover-personaje'
                ]
            }
        ],
        urlId: 'agregarPersonaje'
    },


    'utilizar-flechas': {
        name: (
            <FormattedMessage
                defaultMessage="Utilizar las flechas"
                description="Educabot tutorial"
                id="gui.howtos.utilizar-flechas.name"
            />
        ),
        tags: ['educabot'],
        img: utilizarFlechasFolder,
        steps: [
            {
                title: (
                    <FormattedMessage
                        defaultMessage="Vamos a hacer que nuestro personaje camine utilizando las flechas de teclado"
                        description="Educabot tutorial 5.1"
                        id="gui.howtos.utilizar-flechas-5-1.name"
                    />
                ),
                image: 'educabot51'
            }, {
                title: (
                    <FormattedMessage
                        defaultMessage="Agrega un personaje"
                        description="Educabot tutorial 5.2"
                        id="gui.howtos.utilizar-flechas-5-2.name"
                    />
                ),
                image: 'educabot52'
            }, {
                title: (
                    <FormattedMessage
                        defaultMessage="Elimina el otro personaje"
                        description="Educabot tutorial 5.3"
                        id="gui.howtos.utilizar-flechas-5-3.name"
                    />
                ),
                image: 'educabot53'
            }, {
                title: (
                    <FormattedMessage
                        defaultMessage="Coloca el evento al presionar tecla y selecciona derecha"
                        description="Educabot tutorial 5.4"
                        id="gui.howtos.utilizar-flechas-5-4.name"
                    />
                ),
                image: 'educabot54'
            }, {
                title: (
                    <FormattedMessage
                        defaultMessage="Agrega el bloque fijar estilo de rotación a izquierda-derecha"
                        description="Educabot tutorial 5.5"
                        id="gui.howtos.utilizar-flechas-5-5.name"
                    />
                ),
                image: 'educabot55'
            }, {
                title: (
                    <FormattedMessage
                        defaultMessage="Agrega el bloque apuntar en dirección 90"
                        description="Educabot tutorial 5.6"
                        id="gui.howtos.utilizar-flechas-5-6.name"
                    />
                ),
                image: 'educabot56'
            }, {
                title: (
                    <FormattedMessage
                        defaultMessage="Agrega el bloque mover y cambiá los pasos a 2"
                        description="Educabot tutorial 5.7"
                        id="gui.howtos.utilizar-flechas-5-7.name"
                    />
                ),
                image: 'educabot57'
            }, {
                title: (
                    <FormattedMessage
                        defaultMessage="Agrega el bloque siguiente disfraz"
                        description="Educabot tutorial 5.8"
                        id="gui.howtos.utilizar-flechas-5-8.name"
                    />
                ),
                image: 'educabot58'
            }, {
                title: (
                    <FormattedMessage
                        defaultMessage="Duplica los bloques"
                        description="Educabot tutorial 5.9"
                        id="gui.howtos.utilizar-flechas-5-9.name"
                    />
                ),
                image: 'educabot59'
            }, {
                title: (
                    <FormattedMessage
                        defaultMessage="Cambia a flecha izquierda y la dirección a -90"
                        description="Educabot tutorial 5.10"
                        id="gui.howtos.utilizar-flechas-5-10.name"
                    />
                ),
                image: 'educabot510'
            }, {
                title: (
                    <FormattedMessage
                        defaultMessage="Presiona la flechas del teclado para mover tu personaje"
                        description="Educabot tutorial 5.11"
                        id="gui.howtos.utilizar-flechas-5-11.name"
                    />
                ),
                image: 'educabot511'
            }, {
                deckIds: [
                    'agregar-fondo',
                    'mover-personaje'
                ]
            }
        ],
        urlId: 'utilizarFlechas'
    }

};
