const PropTypes = require('prop-types');
const React = require('react');
const connect = require('react-redux').connect;

// const navigationActions = require('../../redux/navigation.js');
// const Dropdown = require('../dropdown/dropdown.jsx');
// const ConnectedLogin = require('./connected-login.jsx');
import Dropdown from '../dropdown/dropdown.jsx';
import ConnectedLogin from './connected-login.jsx';

import { closeLoginMenu } from '../../reducers/menus';

require('./login-dropdown.scss');

const LoginDropdown = ({
    isOpen,
    onClose,
    onLogIn,
    error
}) => (
    <Dropdown
        className={'with-arrow'}
        isOpen={isOpen}
        key="login-dropdown"
        onRequestClose={onClose}
    >
        <ConnectedLogin
            onLogIn={onLogIn}
            error={error}
        />
    </Dropdown>
);

LoginDropdown.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    onLogIn: PropTypes.func,
    error: PropTypes.string
};

const mapStateToProps = (state, ownProps) => {
    return {
        error: ownProps.error || ''
    }
};

const mapDispatchToProps = dispatch => ({
    onClose: () => {
        dispatch(closeLoginMenu(false));
    },
    // onLogIn: (formData, callback) => {
    //     // dispatch(navigationActions.handleLogIn(formData, callback));
    //     dispatch(handleLogin(formData, callback));
    // }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginDropdown);
