import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import {connect} from 'react-redux';

import {detectTutorialId} from './tutorial-from-url';
import {detectAssignmentId} from './assignment-from-url';
import {detectProjectId} from './project-from-url';

import {activateDeck} from '../reducers/cards';
import {openTipsLibrary} from '../reducers/modals';
import { showAlertWithTimeout } from '../reducers/alerts';

import { startLoadingAssignment, startCloningAssignment, startCreatingAssignment, startLoadingProject } from '../reducers/project-state';
import { setAssignmentSessionId } from '../reducers/assignment-session';
import { getAssignmentSession, getProjectById } from './educabot-api';

/* Higher Order Component to get parameters from the URL query string and initialize redux state
 * @param {React.Component} WrappedComponent: component to render
 * @returns {React.Component} component with query parsing behavior
 */
const QueryParserHOC = function (WrappedComponent) {
    class QueryParserComponent extends React.Component {
        constructor (props) {
            super(props);
            const queryParams = queryString.parse(location.search);
            const tutorialId = detectTutorialId(queryParams);
            if (tutorialId) {
                if (tutorialId === 'all') {
                    this.openTutorials();
                } else {
                    this.setActiveCards(tutorialId);
                }
            }
            const assignmentId = detectAssignmentId(queryParams);
            if (assignmentId) {
                this.setAssignmentId(assignmentId);
            }
            const projectId = detectProjectId(queryParams);
            if (projectId) {
                this.initProject(projectId);
            }
        }
        componentDidUpdate (prevProps) {
            if (this.props.userData && prevProps.assignmentSessionId !== this.props.assignmentSessionId) {
                this.initAssignmentSession(this.props.assignmentSessionId, this.props.userData);
            }
        }
        initProject(id) {
            getProjectById(id).then(data => {
                if (data.boardType && data.boardType === 'animations') {
                    this.props.onStartLoadingProject(id, data);
                } else {
                    this.props.onShowNotAnimationProjectAlert();
                }
            }).catch(err => {
                console.log('[ERROR] getProjectById:', err);
                this.props.onShowErrorAlert();
            });
        }
        initAssignmentSession(id, userData) {
            getAssignmentSession(id).then(result => {
                // console.log('[SUCCESS] getAssignmentSession:', result.data);
                const assignmentSession = result.data;
                let masterProjectId = null;
                let ownProjectId = null;
                const iAmTheMaster = (userData.userId === result.data.userId);
                for (let i = 0; i < assignmentSession.AssignmentSessionsProjects.length; i += 1) {
                    if (assignmentSession.AssignmentSessionsProjects[i].Project.userId === assignmentSession.userId) {
                        masterProjectId = i;
                    }
                    if (assignmentSession.AssignmentSessionsProjects[i].Project.userId === userData.userId) {
                        ownProjectId = i;
                    }
                }
                if (ownProjectId !== null) {
                    // console.log('LOAD MY OWN PROJECT', assignmentSession);
                    const projectToOpen = assignmentSession.AssignmentSessionsProjects[ownProjectId].Project;
                    this.props.onStartLoadingAssignment(id, assignmentSession, projectToOpen.id, projectToOpen);
                } else if (masterProjectId !== null && !iAmTheMaster) {
                    // console.log('CLONE MASTER PROJECT', assignmentSession);
                    const projectToClone = assignmentSession.AssignmentSessionsProjects[masterProjectId].Project;
                    this.props.onStartCloningAssignment(id, assignmentSession, projectToClone);
                } else if (iAmTheMaster) {
                    // console.log('CREATE MASTER PROJECT', assignmentSession);
                    this.props.onStartCreatingAssignment(id, assignmentSession);
                }

            }).catch(err => {
                console.log('[ERROR] getAssignmentSession:', err);
                this.props.onShowPrivateProjectAlert();
            });
        }
        setAssignmentId (assignmentId) {
            this.props.onSetAssignmentSessionId(assignmentId);
        }
        clearAssignmentState () {
            this.props.onClearAssignmentSessionState();
        }
        setActiveCards (tutorialId) {
            this.props.onUpdateReduxDeck(tutorialId);
        }
        openTutorials () {
            this.props.onOpenTipsLibrary();
        }
        render () {
            const {
                /* eslint-disable no-unused-vars */
                onOpenTipsLibrary,
                onUpdateReduxDeck,
                onStartCreatingAssignment,
                onStartCloningAssignment,
                onStartLoadingAssignment,
                onSetAssignmentSessionId,
                userData,
                sessionExists,
                assignmentSessionId,
                /* eslint-enable no-unused-vars */
                ...componentProps
            } = this.props;
            return (
                <WrappedComponent
                    {...componentProps}
                />
            );
        }
    }
    QueryParserComponent.propTypes = {
        onOpenTipsLibrary: PropTypes.func,
        onUpdateReduxDeck: PropTypes.func,
        onSetAssignmentSessionId: PropTypes.func,
        assignmentSessionId: PropTypes.string,
        sessionExists: PropTypes.bool,
        userData: PropTypes.object,
        onStartLoadingAssignment: PropTypes.func,
        onStartCloningAssignment: PropTypes.func,
        onStartCreatingAssignment: PropTypes.func,
        onStartLoadingProject: PropTypes.func,
        onShowErrorAlert: PropTypes.func,
        onShowNotAnimationProjectAlert: PropTypes.func,
        onShowPrivateProjectAlert: PropTypes.func,
    };
    const mapStateToProps = (state, ownProps) => {
        // console.log('[STATETOPROPS]:', state.scratchGui.projectState);
        let assignmentSessionId = null;
        let session = false;
        const sessionState = state.scratchGui.sessionReducer;
        if (sessionState.status === 'session_fetch' && sessionState.data.data.user) {
            session = sessionState.data.data;
            if (state.scratchGui.assignmentSessionReducer
                && state.scratchGui.assignmentSessionReducer.assignmentSessionId
                ) {
                assignmentSessionId = state.scratchGui.assignmentSessionReducer.assignmentSessionId;
            }
        }
        return {
            assignmentSessionId: assignmentSessionId,
            sessionExists: session && typeof session.user !== 'undefined',
            userData: (session) ? session : null,
        }
    };

    const mapDispatchToProps = dispatch => ({
        onOpenTipsLibrary: () => {
            dispatch(openTipsLibrary());
        },
        onUpdateReduxDeck: tutorialId => {
            dispatch(activateDeck(tutorialId));
        },
        onSetAssignmentSessionId: assignmentSessionId => {
            dispatch(setAssignmentSessionId(assignmentSessionId));
        },
        onStartLoadingAssignment: (assignmentId, assignmentData, projectId, projectDetails) => {
            dispatch(startLoadingAssignment(assignmentId, assignmentData, projectId, projectDetails));
        },
        onStartCloningAssignment: (assignmentId, projectDetails, userRole) => {
            dispatch(startCloningAssignment(assignmentId, projectDetails, userRole));
        },
        onStartCreatingAssignment: (assignmentId, assignmentData, userRole) => {
            dispatch(startCreatingAssignment(assignmentId, assignmentData, userRole));
        },
        onStartLoadingProject: (projectId, projectDetails) => dispatch(startLoadingProject(projectId, projectDetails)),
        onShowErrorAlert: () => showAlertWithTimeout(dispatch, 'privateProjectError'),
        onShowNotAnimationProjectAlert: () => showAlertWithTimeout(dispatch, 'notAnimationProjectError'),
        onShowPrivateProjectAlert: () => showAlertWithTimeout(dispatch, 'privateProjectError'),
    });
    return connect(
        mapStateToProps,
        mapDispatchToProps
    )(QueryParserComponent);
};

export {
    QueryParserHOC as default
};
