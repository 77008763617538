import classNames from 'classnames';
import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import { defineMessages, injectIntl, intlShape } from 'react-intl';
const connect = require('react-redux').connect;

import LibraryItem from '../../containers/library-item.jsx';
import Modal from '../../containers/modal.jsx';
import Divider from '../divider/divider.jsx';
import Filter from '../filter/filter.jsx';
import TagButton from '../../containers/tag-button.jsx';
import Spinner from '../spinner/spinner.jsx';
import { getProjects } from '../../lib/educabot-api';

import DeleteButton from '../delete-button/delete-button.jsx';
import Paginator from '../paginator/paginator.jsx';

import styles from './library.css';

const messages = defineMessages({
    filterPlaceholder: {
        id: 'gui.library.filterPlaceholder',
        defaultMessage: 'Search',
        description: 'Placeholder text for library search field'
    },
    allTag: {
        id: 'gui.library.allTag',
        defaultMessage: 'All',
        description: 'Label for library tag to revert to all items after filtering by tag.'
    }
});

const ALL_TAG = { tag: 'all', intlLabel: messages.allTag };
const tagListPrefix = [ALL_TAG];
// const tagListPrefix = [];

class LibraryComponent extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, [
            'handleClose',
            'handleFilterChange',
            'handleFilterClear',
            'handleMouseEnter',
            'handleMouseLeave',
            'handlePlayingEnd',
            'handleSelect',
            'handleTagClick',
            'setFilteredDataRef',
            'processFetchedProjects',
            'handlePageClick',
            'handleSearch'
        ]);
        this.state = {
            playingItem: null,
            filterQuery: '',
            selectedTag: ALL_TAG.tag,
            loaded: false,
            data: [],
            paging: {
                offset: 0,
                count: 0,
                limit: 8,
                params: {}
            }
        };
    }
    componentDidMount() {
        this.props.onLoadProjects(this.state.paging.limit, this.state.paging.offset, { userId: this.props.userData.userId }).then(result => {
            // console.log('FETCHING PROJECTS', result);
            this.processFetchedProjects(result);
        });
        if (this.props.setStopHandler) this.props.setStopHandler(this.handlePlayingEnd);
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevState.filterQuery !== this.state.filterQuery ||
            prevState.selectedTag !== this.state.selectedTag) {
            this.scrollToTop();
        }
    }
    processFetchedProjects(projects) {
        const data = [];
        let jsonBody = {};
        let tags = [];
        const paging = this.state.paging;
        paging.count = projects.count || 0;
        for (let i = 0; i < projects.rows.length; i += 1) {
            jsonBody = projects.rows[i].jsonBody;
            if (projects.rows[i].boardType === 'animations') {
                tags = [];
                if (projects.rows[i].isLibrary) {
                    tags.push('smartTeam');
                } else {
                    tags.push((projects.rows[i].isPublic) ? 'public' : 'private');
                }
                data.push({
                    "id": projects.rows[i].id,
                    "userId": projects.rows[i].userId,
                    "name": projects.rows[i].title,
                    "description": projects.rows[i].description,
                    "boardType": projects.rows[i].boardType,
                    "iconBase64": jsonBody.projectImg,
                    "data": projects.rows[i],
                    "tags": tags
                });
            }
        }
        this.setState({ data, loaded: true, paging });
    }
    handleSelect(id) {
        this.handleClose();
        this.props.onItemSelected(this.getFilteredData()[id]);
    }
    handleClose() {
        this.props.onRequestClose();
    }
    handleTagClick(tag) {
        if (this.state.playingItem === null) {
            const paging = this.state.paging;
            const params = {};
            if (tag.toLowerCase() === 'private') {
                params.userId = this.props.userData.userId;
                params.isPublic = false;
                params.isLibrary = false;
            } else if (tag.toLowerCase() === 'public') {
                params.isPublic = true;
            } else if (tag.toLowerCase() === 'smartteam') {
                params.isLibrary = true;
            } else {
                params.userId = this.props.userData.userId;
            }
            params.search = null;

            paging.params = params;
            paging.offset = 0;
            this.props.onLoadProjects(this.state.paging.limit, this.state.paging.offset, params).then(result => {
                this.processFetchedProjects(result);
            });
            this.setState({
                paging,
                loaded: false,
                filterQuery: '',
                selectedTag: tag.toLowerCase()
            });
        } else {
            this.props.onItemMouseLeave(this.getFilteredData()[[this.state.playingItem]]);
            this.setState({
                filterQuery: '',
                playingItem: null,
                selectedTag: tag.toLowerCase()
            });
        }
    }
    handlePageClick(page) {
        const paging = this.state.paging;
        paging.offset = page * paging.limit;
        this.setState({
            paging,
            loaded: false,
        }, () => {
            this.props.onLoadProjects(this.state.paging.limit, this.state.paging.offset, this.state.paging.params).then(result => {
                this.processFetchedProjects(result);
            });
        });

    }
    handleMouseEnter(id) {
        // don't restart if mouse over already playing item
        if (this.props.onItemMouseEnter && this.state.playingItem !== id) {
            this.props.onItemMouseEnter(this.getFilteredData()[id]);
            this.setState({
                playingItem: id
            });
        }
    }
    handleMouseLeave(id) {
        if (this.props.onItemMouseLeave) {
            this.props.onItemMouseLeave(this.getFilteredData()[id]);
            this.setState({
                playingItem: null
            });
        }
    }
    handlePlayingEnd() {
        if (this.state.playingItem !== null) {
            this.setState({
                playingItem: null
            });
        }
    }
    handleSearch(event) {
        const paging = this.state.paging;
        paging.offset = 0;
        paging.params.search = event.target.value;

        if (this.state.playingItem === null) {
            this.setState({
                paging,
                filterQuery: event.target.value,
            }, () => {
                this.props.onLoadProjects(this.state.paging.limit, this.state.paging.offset, this.state.paging.params).then(result => {
                    this.processFetchedProjects(result);
                });
            });
        } else {
            this.setState({
                paging,
                filterQuery: event.target.value,
                playingItem: null,
            });
        }
    }
    handleFilterChange(event) {
        if (this.state.playingItem === null) {
            this.setState({
                filterQuery: event.target.value,
            });
        } else {
            this.setState({
                filterQuery: event.target.value,
                playingItem: null,
            });
        }
    }
    handleFilterClear() {
        const paging = this.state.paging;
        paging.offset = 0;
        paging.params.search = null;
        this.setState({ filterQuery: '', paging }, () => {
            this.props.onLoadProjects(this.state.paging.limit, this.state.paging.offset, this.state.paging.params).then(result => {
                this.processFetchedProjects(result);
            });
        });
    }
    getFilteredData() {
        return this.state.data;
    }
    scrollToTop() {
        this.filteredDataRef.scrollTop = 0;
    }
    setFilteredDataRef(ref) {
        this.filteredDataRef = ref;
    }
    isMyProject(projectUserId) {
        return (
            this.props.userData &&
            projectUserId &&
            projectUserId.toString() === this.props.userData.userId.toString()
        ) ? true : false;
    }
    render() {
        return (
            <Modal
                fullScreen
                contentLabel={this.props.title}
                id={this.props.id}
                onRequestClose={this.handleClose}
            >
                {(this.props.filterable || this.props.tags) && (
                    <div className={styles.filterBar}>
                        {this.props.filterable && (
                            <Filter
                                className={classNames(
                                    styles.filterBarItem,
                                    styles.filter
                                )}
                                filterQuery={this.state.filterQuery}
                                inputClassName={styles.filterInput}
                                placeholderText={this.props.intl.formatMessage(messages.filterPlaceholder)}
                                onSearch={this.handleSearch}
                                onChange={this.handleFilterChange}
                                onClear={this.handleFilterClear}
                            />
                        )}
                        {this.props.filterable && this.props.tags && (
                            <Divider className={classNames(styles.filterBarItem, styles.divider)} />
                        )}
                        {this.props.tags &&
                            <div className={styles.tagWrapper}>
                                {tagListPrefix.concat(this.props.tags).map((tagProps, id) => (
                                    <TagButton
                                        active={this.state.selectedTag === tagProps.tag.toLowerCase()}
                                        className={classNames(
                                            styles.filterBarItem,
                                            styles.tagButton,
                                            tagProps.className
                                        )}
                                        key={`tag-button-${id}`}
                                        onClick={this.handleTagClick}
                                        {...tagProps}
                                    />
                                ))}
                            </div>
                        }
                    </div>
                )}
                <div
                    className={classNames(styles.libraryScrollGrid, {
                        [styles.withFilterBar]: this.props.filterable || this.props.tags
                    })}
                    ref={this.setFilteredDataRef}
                >
                    {this.state.loaded ? this.getFilteredData().map((dataItem, index) => (
                        <React.Fragment key={`fragment-${dataItem.id}`}>
                            <div className={styles.projectCard}>
                                {(this.isMyProject(dataItem.userId)) ? (
                                    <DeleteButton
                                        className={classNames(styles.deleteButton)}
                                        onClick={() => this.props.onDeleteProject(dataItem.id)}
                                    />
                                ) : ('')}
                                <LibraryItem
                                    tags={dataItem.tags}
                                    bluetoothRequired={dataItem.bluetoothRequired}
                                    collaborator={dataItem.collaborator}
                                    description={dataItem.description}
                                    disabled={dataItem.disabled}
                                    extensionId={dataItem.extensionId}
                                    featured={dataItem.featured}
                                    hidden={dataItem.hidden}
                                    iconMd5={dataItem.md5}
                                    iconRawURL={dataItem.rawURL}
                                    iconBase64={dataItem.iconBase64}
                                    icons={dataItem.json && dataItem.json.costumes}
                                    id={index}
                                    insetIconURL={dataItem.insetIconURL}
                                    internetConnectionRequired={dataItem.internetConnectionRequired}
                                    isPlaying={this.state.playingItem === index}
                                    key={typeof dataItem.name === 'string' ? `${dataItem.name}-${dataItem.id}` : dataItem.id}
                                    name={dataItem.name}
                                    showPlayButton={this.props.showPlayButton}
                                    onMouseEnter={this.handleMouseEnter}
                                    onMouseLeave={this.handleMouseLeave}
                                    onSelect={this.handleSelect}
                                />
                            </div>
                        </React.Fragment>
                    )) : (
                        <div className={styles.spinnerWrapper}>
                            <Spinner
                                large
                                level="primary"
                            />
                        </div>
                    )}
                </div>
                <Paginator
                    offset={this.state.paging.offset}
                    count={this.state.paging.count}
                    limit={this.state.paging.limit}
                    onPageChange={this.handlePageClick}
                />
            </Modal>
        );
    }
}

LibraryComponent.propTypes = {
    filterable: PropTypes.bool,
    id: PropTypes.string.isRequired,
    intl: intlShape.isRequired,
    onItemMouseEnter: PropTypes.func,
    onItemMouseLeave: PropTypes.func,
    onItemSelected: PropTypes.func,
    onRequestClose: PropTypes.func,
    setStopHandler: PropTypes.func,
    showPlayButton: PropTypes.bool,
    tags: PropTypes.arrayOf(PropTypes.shape(TagButton.propTypes)),
    title: PropTypes.string.isRequired,
    onLoadProjects: PropTypes.func,
    onDeleteProject: PropTypes.func,
    userData: PropTypes.object,
};

LibraryComponent.defaultProps = {
    filterable: true,
    showPlayButton: false,
    userData: {},
};

const IntlLibraryComponent = injectIntl(LibraryComponent);

const mapStateToProps = state => {
    return {
        onLoadProjects: getProjects,
    };
};

const ConnectedLibraryComponent = connect(
    mapStateToProps,
    () => ({}),
)(IntlLibraryComponent);

export default ConnectedLibraryComponent;
