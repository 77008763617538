/**
 * @fileoverview
 * Utility function to detect project id from query paramenter on the URL.
 */

/**
 * Check if there's a project id provided as a query parameter in the URL.
 * Return the corresponding project id or null if not found.
 * @param {object} queryParams the results of parsing the query string
 * @return {string} The ID of the requested project or null if no project was
 * requested or found.
 */
const detectProjectId = queryParams => {
    const projectID = Array.isArray(queryParams.proyecto) ?
        queryParams.proyecto[0] :
        queryParams.proyecto;
    if (typeof projectID === 'undefined') return null;
    return projectID;
};

export {
    detectProjectId
};
