const bindAll = require('lodash.bindall');
const connect = require('react-redux').connect;
const defaults = require('lodash.defaultsdeep');
const PropTypes = require('prop-types');
const React = require('react');

import {
    checkAuthAction,
    handleRegistration,
} from '../../reducers/session';

// const api = require('../../lib/api');
// const injectIntl = require('../../lib/intl.jsx').injectIntl;
const injectIntl = require('react-intl').injectIntl;
// const intlShape = require('../../lib/intl.jsx').intlShape;
const intlShape = require('react-intl').intlShape;
// const sessionActions = require('../../redux/session.js');
const validate = require('../../lib/validate');

import Progression from '../progression/progression.jsx';
import UsernameStep from './username-step.jsx';
import NameStep from './name-step.jsx';
import WelcomeStep from './welcome-step.jsx';
import RegistrationErrorStep from './registration-error-step.jsx';

class JoinFlow extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'handleAdvanceStep',
            'handleCaptchaError',
            'handleErrorNext',
            'handlePrepareToRegister',
            'handleRegistrationResponse',
            'handleSubmitRegistration'
        ]);
        this.initialState = {
            numAttempts: 0,
            formData: {},
            registrationError: null,
            step: 0,
            waiting: false
        };
        // it's ok to set state by reference, because state is treated as immutable,
        // so any changes to its fields will result in a new state which does not
        // reference its past fields
        this.state = this.initialState;
    }
    canTryAgain () {
        return (this.state.registrationError.errorAllowsTryAgain && this.state.numAttempts <= 1);
    }
    handleCaptchaError () {
        this.setState({
            registrationError: {
                errorAllowsTryAgain: false,
                errorMsg: this.props.intl.formatMessage({
                    id: 'registration.errorCaptcha'
                })
            }
        });
    }
    handlePrepareToRegister (newFormData) {
        newFormData = newFormData || {};
        const newState = {
            formData: defaults({}, newFormData, this.state.formData)
        };
        this.setState(newState, () => {
            this.handleSubmitRegistration(this.state.formData);
        });
    }
    getErrorsFromResponse (res) {
        const errorsFromResponse = [];
        if (res.status === 'ERROR' && res.message) {
            const responseBodyErrors = res.message;
            if (responseBodyErrors) {
                errorsFromResponse.push({fieldName: 'email', errorStr: responseBodyErrors});
            }
        }
        return errorsFromResponse;
    }
    getCustomErrMsg (errorsFromResponse) {
        if (!errorsFromResponse || errorsFromResponse.length === 0) return null;
        let customErrMsg = '';
        // body can include zero or more error objects. Here we assemble
        // all of them into a single string, customErrMsg.
        errorsFromResponse.forEach(errorFromResponse => {
            if (customErrMsg.length) customErrMsg += '; ';
            customErrMsg += `${errorFromResponse.fieldName}: ${errorFromResponse.errorStr}`;
        });
        const problemsStr = this.props.intl.formatMessage({id: 'registration.problemsAre'});
        return `${problemsStr}: "${customErrMsg}"`;
    }
    registrationIsSuccessful (res) {
        console.log('RESPONSE:::', res);
        return !!(!res || res.status !== 'ERROR');
    }
    handleRegistrationResponse (res) {
        console.log('REGISTRATION RESPONSE:::', res);
        this.setState({
            numAttempts: this.state.numAttempts + 1
        }, () => {
            const success = this.registrationIsSuccessful(res);
            if (success) {
                this.setState({
                    step: this.state.step + 1,
                    waiting: false
                });
                // this.props.checkAuth().then(() => {
                //     this.setState({
                //         step: this.state.step + 1,
                //         waiting: false
                //     });
                // });
                return;
            }
            // now we know something went wrong -- either an actual error (client-side
            // or server-side), or just a problem with the registration content.

            // if an actual error, prompt user to try again.
            // if (res.status === 'ERROR') {
            //     this.setState({
            //         registrationError: {errorAllowsTryAgain: true},
            //         waiting: false
            //     });
            //     return;
            // }

            // now we know there was a problem with the registration content.
            // If the server provided us info on why registration failed,
            // build a summary explanation string
            let errorMsg = null;
            const errorsFromResponse = this.getErrorsFromResponse(res);
            // if there was exactly one error, check if we have a pre-written message
            // about that precise error
            if (errorsFromResponse.length === 1) {
                const singleErrMsgId = validate.responseErrorMsg(
                    errorsFromResponse[0].fieldName,
                    errorsFromResponse[0].errorStr
                );
                if (singleErrMsgId) { // one error that we have a predefined explanation string for
                    errorMsg = this.props.intl.formatMessage({id: singleErrMsgId});
                }
            }
            // if we have more than one error, build a custom message with all of the
            // server-provided error messages
            if (!errorMsg && errorsFromResponse.length > 0) {
                errorMsg = this.getCustomErrMsg(errorsFromResponse);
            }
            this.setState({
                registrationError: {
                    errorAllowsTryAgain: false,
                    errorMsg: errorMsg
                },
                waiting: false
            });
        });
    }
    handleSubmitRegistration (formData) {
        this.setState({
            registrationError: null, // clear any existing error
            waiting: true
        }, () => {
            this.props.onRegistrationSubmit({
                email: formData.email,
                password: formData.password,
                name: formData.firstName,
                lastname: formData.lastName,
                
            }, this.handleRegistrationResponse);
        });
    }
    handleAdvanceStep (newFormData) {
        newFormData = newFormData || {};
        this.setState({
            formData: defaults({}, newFormData, this.state.formData),
            step: this.state.step + 1
        });
    }
    handleErrorNext () {
        if (this.canTryAgain()) {
            this.handleSubmitRegistration(this.state.formData);
        } else {
            this.resetState();
        }
    }
    resetState () {
        this.setState(this.initialState);
    }
    sendAnalytics (path) {
        const gaID = window.GA_ID;
        if (!window.ga) {
            return;
        }
        window.ga('send', {
            hitType: 'pageview',
            page: path,
            tid: gaID
        });
    }

    render () {
        return (
            <React.Fragment>
                {this.state.registrationError ? (
                    <RegistrationErrorStep
                        canTryAgain={this.canTryAgain()}
                        errorMsg={this.state.registrationError.errorMsg}
                        sendAnalytics={this.sendAnalytics}
                        /* eslint-disable react/jsx-no-bind */
                        onSubmit={this.handleErrorNext}
                        /* eslint-enable react/jsx-no-bind */
                    />
                ) : (
                    <Progression step={this.state.step}>
                        <UsernameStep
                            onNextStep={this.handleAdvanceStep}
                        />
                        <NameStep
                            onNextStep={this.handlePrepareToRegister}
                        />
                        <WelcomeStep
                            createProjectOnComplete={this.props.createProjectOnComplete}
                            email={this.state.formData.email}
                            sendAnalytics={this.sendAnalytics}
                            username={this.state.formData.firstName || this.state.formData.username}
                            onNextStep={this.props.onCompleteRegistration}
                        />
                    </Progression>
                )}
            </React.Fragment>
        );
    }
}

JoinFlow.propTypes = {
    createProjectOnComplete: PropTypes.bool,
    intl: intlShape,
    onCompleteRegistration: PropTypes.func,
    checkAuth: PropTypes.func,
    onRegistrationSubmit: PropTypes.func,
    refreshSessionWithRetry: PropTypes.func,
};

const IntlJoinFlow = injectIntl(JoinFlow);

const mapDispatchToProps = dispatch => ({
    checkAuth: () => dispatch(checkAuthAction()),
    onRegistrationSubmit: (formData, callback) => dispatch(handleRegistration(formData, callback)),
});

// Allow incoming props to override redux-provided props. Used to mock in tests.
const mergeProps = (stateProps, dispatchProps, ownProps) => {
    // console.log('----------', stateProps, dispatchProps, ownProps);
    return Object.assign({}, stateProps, dispatchProps, ownProps);
};

const ConnectedJoinFlow = connect(
    () => ({}),
    mapDispatchToProps,
    mergeProps
)(IntlJoinFlow);

export default ConnectedJoinFlow;
